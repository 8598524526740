const { default: axiosInstance } = require('../axiosInstance');

const removeTeamFromEvent = async (eventId, teamId) => {
  return axiosInstance.delete(`/team/remove/${eventId}/${teamId}`).then((response) => {
    return response.data;
  });
};

const teamService = {
  removeTeamFromEvent
};

export default teamService;

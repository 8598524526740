import React, { useEffect, useState } from 'react';
import {
  SingleEliminationBracket,
  MATCH_STATES,
  SVGViewer,
  createTheme
} from 'react-tournament-brackets';
import { useWindowSize } from '@uidotdev/usehooks';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { store } from '../../../config/firebase';
import AddGameDialog from '../../games/addGameDialog';
import GameByStatusCards from '../gameStatusCards/gameByStatusCards';
import ColorDot from '../colorDot';
import { GameStatus } from '../../../constants/gameConstants';
import { useColorMode } from '../../../hooks/colorModeProvider';
import { useAuth } from '../../../contexts/authContext';

const SingleBracket = ({ bracket }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [openGame, setOpenGame] = useState(false);
  const { authenticated, currentUserProfile } = useAuth();

  let strMatches = JSON.stringify(bracket.matches);
  let matches = JSON.parse(strMatches);

  const size = useWindowSize();
  const finalWidth = Math.max(size.width - 20);
  const finalHeight = Math.max(size.height - 200);
  const theme = useTheme();
  const { mode } = useColorMode();

  useEffect(() => {
    //Get all games from firebase connected to this bracket.
    setLoading(true);
    setGames([]);
    const collectionRef = collection(store, 'games');
    const q = query(collectionRef, where('bracket', '==', bracket.id));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const gamesData = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data()
      }));

      setGames(gamesData);
    });

    setLoading(false);

    return () => unsubscribe();
  }, [bracket]);

  const WhiteTheme = createTheme({
    textColor: {
      main: theme.palette.text.primary,
      highlighted: theme.palette.text.primary,
      dark: theme.palette.text.primary
    },
    matchBackground: {
      wonColor: mode === 'dark' ? theme.palette.background.default : '#0a0e21',
      lostColor: mode === 'dark' ? theme.palette.background.default : '#0a0e21'
    },
    score: {
      background: { wonColor: theme.palette.success.main, lostColor: theme.palette.error.main },
      text: {
        highlightedWonColor: theme.palette.text.primary,
        highlightedLostColor: theme.palette.text.primary
      }
    },
    border: {
      color: theme.palette.primary.dark,
      highlightedColor: theme.palette.primary.main
    },
    roundHeader: {
      backgroundColor:
        mode === 'dark' ? theme.palette.background.default : theme.palette.primary.main,
      fontColor: mode === 'dark' ? theme.palette.text.primary : '#fff'
    },
    connectorColor: theme.palette.text.primary,
    connectorColorHighlight: theme.palette.yellow.main,
    svgBackground: theme.palette.background.main
  });

  const handleOpenGame = (game) => {
    setSelectedMatch(game);
    setOpenGame(true);
  };

  const limitNameLength = (name) => {
    if (name.length > 20) {
      return name.substring(0, 30) + '...';
    }
    return name;
  };

  return (
    <>
      <SingleEliminationBracket
        matches={matches}
        theme={WhiteTheme}
        options={{
          style: {
            roundHeader: {
              backgroundColor: WhiteTheme.roundHeader.backgroundColor,
              fontColor: WhiteTheme.roundHeader.fontColor
            },
            connectorColor: WhiteTheme.connectorColor,
            connectorColorHighlight: WhiteTheme.connectorColorHighlight
          }
        }}
        svgWrapper={({ children, ...props }) => (
          <SVGViewer
            width={finalWidth}
            height={finalHeight}
            background={WhiteTheme.svgBackground}
            SVGBackground={WhiteTheme.svgBackground}
            //style={{ strokeWidth: '2px' }}
            {...props}>
            {children}
          </SVGViewer>
        )}
        matchComponent={({
          match,
          onMatchClick,
          onPartyClick,
          onMouseEnter,
          onMouseLeave,
          topParty,
          bottomParty,
          topWon,
          bottomWon,
          topHovered,
          bottomHovered,
          topText,
          bottomText,
          connectorColor,
          computedStyles,
          teamNameFallback,
          resultFallback
        }) => {
          //Set topResult to  an '🏆' of won and '🥈' if lost and '🏅' if draw
          let topWonResult = topWon ? '🏆' : '🥈';
          let bottomWonResult = bottomWon ? '🏆' : '🥈';

          let topResult = topParty.resultText ?? resultFallback(topParty);
          let bottomResult = bottomParty.resultText ?? resultFallback(bottomParty);

          if (topResult === 'DRAW' && bottomResult === 'DRAW') {
            topWonResult = '🏅';
            bottomWonResult = '🏅';
          }

          if (match.state === MATCH_STATES.DONE) {
            topResult = topWonResult;
            bottomResult = bottomWonResult;
          }

          const thisGame = games.find((game) => game.match === match.id);
          const canBeGame =
            topParty.id?.indexOf('section-') === -1 && bottomParty.id?.indexOf('section-') === -1;

          let teamAColor = '';
          let teamBColor = '';
          let teamATotal = 0;
          let teamBTotal = 0;

          if (thisGame) {
            teamAColor = thisGame.teams.teamA.color;
            teamBColor = thisGame.teams.teamB.color;

            //Get Current Set
            if (thisGame.status === GameStatus.IN_PROGRESS) {
              const currentSet = thisGame.sets.find((set) => set.id === thisGame.currentSet);
              if (thisGame.totalSetScore) {
                teamATotal = currentSet.totalSetScore[topParty.id] || 0;
                teamBTotal = currentSet.totalSetScore[bottomParty.id] || 0;
              }
            } else if (thisGame.status === GameStatus.COMPLETE) {
              if (thisGame.teamTotals) {
                teamATotal = thisGame.teamTotals[topParty.id] || 0;
                teamBTotal = thisGame.teamTotals[bottomParty.id] || 0;
              }
            }
          }

          return (
            <Paper
              sx={{
                width: '100%',
                maxWidth: 450,
                p: [1, 1],
                borderRadius: 5,
                backgroundColor: mode === 'dark' ? theme.palette.background.paper : '#0a0e21',
                color: mode === 'dark' ? theme.palette.text.primary : '#fff'
                // position: 'relative'
              }}
              elevation={3}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'100%'}>
                <Typography variant="caption">{match.name}</Typography>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
                sx={{ cursor: 'pointer', color: topHovered && theme.palette.yellow.main }}
                onMouseEnter={() => onMouseEnter(topParty.id)}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'flex-start'}
                  gap={2}
                  alignItems={'center'}
                  width={'100%'}>
                  <ColorDot color={teamAColor} noOffset />
                  <Typography variant="caption">
                    {limitNameLength(topParty.name) || teamNameFallback}
                  </Typography>
                </Box>

                <Typography variant="caption">{topResult || teamATotal}</Typography>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
                sx={{ cursor: 'pointer', color: bottomHovered && theme.palette.yellow.main }}
                onMouseEnter={() => onMouseEnter(bottomParty.id)}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'flex-start'}
                  gap={2}
                  alignItems={'center'}
                  width={'100%'}>
                  <ColorDot color={teamBColor} noOffset />
                  <Typography variant="caption">
                    {limitNameLength(bottomParty.name) || teamNameFallback}
                  </Typography>
                </Box>
                <Typography variant="caption">{bottomResult || teamBTotal}</Typography>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'100%'}>
                {loading ? (
                  <CircularProgress color={'yellow.main'} />
                ) : canBeGame ? (
                  thisGame ? (
                    <Button
                      variant={'outlined'}
                      size={'small'}
                      color={'yellow'}
                      onClick={() => handleOpenGame(thisGame)}
                      onTouchStart={() => handleOpenGame(thisGame)}>
                      View Game
                    </Button>
                  ) : (
                    authenticated && (
                      <AddGameDialog
                        teamAId={topParty.id}
                        teamBId={bottomParty.id}
                        customName={match.name}
                        bracketId={bracket.id}
                        matchId={match.id}
                      />
                    )
                  )
                ) : null}
              </Box>
            </Paper>
          );
        }}
      />

      {selectedMatch && (
        <Dialog open={openGame} onClose={() => setOpenGame(false)}>
          <DialogContent>
            <GameByStatusCards game={selectedMatch} />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="contained" onClick={() => setOpenGame(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default SingleBracket;

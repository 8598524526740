import React from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Scoreboard from '../ui/gameControls/scoreboard';
import ScoringDisplay from '../ui/playerDisplay/scoringDisplay';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PublicScoreView = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [fsGame, setFSGame] = React.useState({});

  React.useEffect(() => {
    const docRef = doc(store, 'games', gameId);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const gameData = { id: docSnap.id, ...docSnap.data() };
        setFSGame(gameData);
      } else {
        console.log('No game found!');
      }
    });

    return () => unsubscribe();
  }, [gameId]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={2}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{ position: 'absolute', left: 10 }}
          color="info">
          Back
        </Button>

        <Typography variant="h4">Section Games</Typography>
      </Box>
      {fsGame ? (
        <Box>
          <Typography variant="h5" textAlign={'center'} mb={2}>
            {fsGame.name ?? 'Private Game'}
          </Typography>
          <ScoringDisplay game={fsGame} />
          {fsGame.sets && <Scoreboard game={fsGame} />}
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default PublicScoreView;

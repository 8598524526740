import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input';

const PhoneNumberInput = ({
  name,
  label,
  control,
  defaultValue,
  helperText,
  disabled,
  ...props
}) => {
  return (
    <Controller
      render={({ field: { onChange, onBlur, value } }) => (
        <FormControl fullWidth disabled={disabled} {...props}>
          <MuiTelInput
            variant="outlined"
            size="small"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            defaultCountry={'za'}
          />
          <FormHelperText error={props.error}>{helperText}</FormHelperText>
        </FormControl>
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default PhoneNumberInput;

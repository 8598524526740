import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { Dashboard, Event, People as UsersIcon } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { hasAccess, Roles } from '../../../helpers/access';
import { useAuth } from '../../../contexts/authContext';

export default function BSBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const { currentUserProfile } = useAuth();

  const navItems = [
    {
      text: 'Overview',
      link: '/dashboard',
      icon: <Dashboard />,
      access: Roles.USER
    },
    {
      text: 'Events',
      link: '/events',
      icon: <Event />,
      access: Roles.USER
    },
    {
      text: 'Users',
      link: '/users',
      icon: <UsersIcon />,
      access: Roles.ADMIN
    }
  ];

  // Update the value state based on the current path
  React.useEffect(() => {
    const currentPath = location.pathname;
    const currentIndex = navItems.findIndex((item) => item.link === currentPath);
    if (currentIndex !== -1) {
      setValue(currentIndex);
    }
  }, [location.pathname, navItems]);

  return (
    <Box width={'100%'} position={'fixed'} bottom={0}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(navItems[newValue].link);
        }}>
        {navItems.map(
          (item, index) =>
            hasAccess(currentUserProfile, item.access) && (
              <BottomNavigationAction key={index} label={item.text} icon={item.icon} />
            )
        )}
      </BottomNavigation>
    </Box>
  );
}

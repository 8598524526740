import { Box, Typography } from '@mui/material';

const GameSectionWins = ({ game }) => {
  const getTeamById = (teamId) => {
    return game.teams.teamA.id === teamId ? game.teams.teamA : game.teams.teamB;
  };

  const teamName = (team) =>
    team.players?.map((player, index) => (
      <Typography key={index} variant="caption">
        {player}
      </Typography>
    ));

  return (
    <Box display="flex" flexDirection="row" gap={3} justifyContent={'center'} alignItems={'center'}>
      {game.sets?.map((set, index) => {
        return (
          set.isDone && (
            <Box key={index}>
              <Typography variant="caption">
                Set {set.setNumber}: {teamName(getTeamById(set.winningId))}
              </Typography>
            </Box>
          )
        );
      })}
    </Box>
  );
};

export default GameSectionWins;

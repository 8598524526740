import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PrivateLayout from './privateLayout';

import { useAuth } from '../contexts/authContext';
import { hasAccess } from '../helpers/access';

const PrivateRoute = ({ role }) => {
  const { authenticated, currentUserProfile } = useAuth();

  // if (authenticated && !currentUserProfile) {
  //   return <Navigate to={'/user/profile'} />;
  // }

  if (!authenticated) {
    return <Navigate to={'/signin'} />;
  }

  if (role) {
    if (!hasAccess(currentUserProfile, role)) {
      return <Navigate to={'/'} />;
    }
  }

  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
};

export default PrivateRoute;

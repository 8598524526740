import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteEvent, getAllEvents, getAllEventsByVenue } from '../../features/event/eventSlice';
import {
  Typography,
  Box,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Fab
} from '@mui/material';
import PrimaryButton from '../ui/buttons/primaryButton';
import AddEditEventDialog from './addEditEventDialog';
import { useTheme } from '@mui/material/styles';

import { Dialog } from '@mui/material';
import moment from 'moment';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../hooks/titleProvider';
import ImportEventDialog from './importEventDialog';
import EventCard from '../ui/cards/eventCard';

import { useAuth } from '../../contexts/authContext';
import { Roles, hasAccess } from '../../helpers/access';

const Events = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.event.events);
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const loadingEvents = useSelector((state) => state.event.loading);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [importEvent, setImportEvent] = useState(null);
  const [sortedEvents, setSortedEvents] = useState([]);
  const theme = useTheme();
  const isMobile = theme.breakpoints.down('sm');
  const navigate = useNavigate();

  const { authenticated, currentUserProfile } = useAuth();

  const { setTitle } = useTitle();
  setTitle('Events');

  useEffect(() => {
    if (selectedVenue) {
      dispatch(getAllEventsByVenue(selectedVenue.id));
    }
  }, [dispatch, selectedVenue]);

  useEffect(() => {
    if (events.length > 0) {
      const _sortedEvents = [...events].sort((a, b) => {
        return moment(a.startDate).isBefore(moment(b.startDate)) ? 1 : -1;
      });

      setSortedEvents(_sortedEvents);
    }
  }, [events]);

  const handleEditOpen = async (event) => {
    setSelectedEvent(event);
    setOpenEdit(true);
  };

  const handleEditClose = async () => {
    setSelectedEvent(null);
    setOpenEdit(false);
  };

  const handleDeleteClose = async () => {
    setOpenDelete(false);
  };

  const handleDeleteOpen = async (event) => {
    setSelectedEvent(event);
    setOpenDelete(true);
  };

  const handleConfirm = async () => {
    if (selectedEvent) {
      dispatch(deleteEvent(selectedEvent.id));
    }

    setOpenDelete(false);
  };

  const handleImport = (clubMasterEvent) => {
    setImportEvent(clubMasterEvent);
    setOpenEdit(true);
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={2}>
        <Typography variant="h5" component="h1">
          Events
        </Typography>
        {hasAccess(currentUserProfile, Roles.ADMIN) && (
          <Box>
            <Fab size="small" color="primary" aria-label="add" onClick={() => handleEditOpen()}>
              <Add />
            </Fab>
            <ImportEventDialog onImport={(clubMasterEvent) => handleImport(clubMasterEvent)} />
          </Box>
        )}
      </Box>
      {/* <Paper sx={{ p: 2 }}> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        {selectedEvent ? (
          <AddEditEventDialog
            open={openEdit}
            handleClose={() => handleEditClose()}
            event={selectedEvent}
            importedEvent={importEvent}
          />
        ) : (
          <AddEditEventDialog
            open={openEdit}
            handleClose={() => handleEditClose()}
            importedEvent={importEvent}
          />
        )}

        {!loadingEvents ? (
          sortedEvents.map((event, index) => (
            <EventCard event={event} onEditOpen={handleEditOpen} />
          ))
        ) : (
          <Box
            display="flex"
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            m={5}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{selectedEvent ? selectedEvent.name : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this event? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton variant="contained" onClick={handleDeleteClose}>
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleConfirm} autoFocus>
            Confirm
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      {/* </Paper> */}
    </>
  );
};

export default Events;

import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { Fragment, useState } from 'react';
import AddEditUserForm from './addEditForm';

const InviteDialog = ({ onClose }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <Button variant="contained" onClick={handleOpen}>
        Invite User
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent>
          <AddEditUserForm onSuccess={handleClose} onCancel={handleClose} />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default InviteDialog;

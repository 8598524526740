import React from 'react';
import './ticker.css';
import { Box } from '@mui/material';

const Ticker = ({ children }) => {
  return (
    <Box
      className="ticker-container"
      sx={{
        position: 'absolute', // Anchor to the bottom
        bottom: 20,
        width: '100%',
        display: 'flex',
        gap: 2,
        overflowX: 'auto',
        scrollbarWidth: 'none', // For Firefox
        '&::-webkit-scrollbar': { display: 'none' } // For Webkit-based browsers
      }}>
      <Box
        className="ticker-content"
        sx={{
          display: 'flex',
          gap: 2,
          animation: 'ticker-scroll 30s linear infinite', // Slow down animation by increasing duration
          animationDelay: '-15s' // Adjust delay to match new duration
        }}>
        {children}
      </Box>
    </Box>
  );
};

export default Ticker;

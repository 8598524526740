import { useDispatch } from 'react-redux';
import { Box, Stack } from '@mui/material';
import PrimaryButton from '../buttons/primaryButton';
import MultiplePlayerDisplay from '../playerDisplay/multiple';
import { startGame } from '../../../features/game/gameSlice';

import { useAuth } from '../../../contexts/authContext';
import { Roles, hasAccess } from '../../../helpers/access';

const GameNotStartedCard = ({ event, game, actions }) => {
  const dispatch = useDispatch();

  const { currentUser, currentUserProfile } = useAuth();

  const gameScorer = game.scorer?.id || game.scorer;

  const handleStartGame = () => {
    dispatch(startGame(game.id))
      .unwrap()
      .then(() => {
        //navigate(`/game/scoring/${game.id}`);
      });
  };

  return (
    <Stack justifyContent={'space-between'} height="100%">
      <MultiplePlayerDisplay teams={game.teams} />

      <Box display={'flex'} flexDirection={'row'} alignSelf={'center'} justifySelf={'flex-end'}>
        {(hasAccess(currentUserProfile, Roles.SCORER) ||
          (currentUser && currentUser.uid === gameScorer)) && (
          <PrimaryButton color="success" onClick={() => handleStartGame()}>
            Start Game
          </PrimaryButton>
        )}
      </Box>
    </Stack>
  );
};

export default GameNotStartedCard;

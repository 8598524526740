import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  MenuItem,
  Typography,
  CircularProgress,
  Button,
  Fab,
  useTheme,
  useMediaQuery
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';

import PrimaryButton from '../ui/buttons/primaryButton';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Select from '../ui/forms/fields/select';
import Checkbox from '../ui/forms/fields/checkbox';
import gameFormatService, { GameType } from '../../features/gameFormat/gameFormatService';
import EventMultipleVs from './eventMultipleVs';
import { addEventGame, getEvent } from '../../features/event/eventSlice';
import GreenSelector from '../ui/clubControls/greenSelector';
import { createGame } from '../../features/game/gameSlice';
import EventSinglesVs from './eventSinglesVs';
import OverrideGameFormat from '../gameFormats/override';

import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';

const schema = yup.object().shape({
  teamA: yup.string().required('Team A is required'),
  teamB: yup.string().required('Team B is required'),
  teamAColor: yup.string(),
  teamBColor: yup.string(),
  teams: yup.object().required('Teams are required'),
  round: yup.number(),
  rink: yup.number(),
  green: yup.string(),
  live: yup.boolean(),
  featured: yup.boolean()
});

const AddGameDialog = ({
  section,
  game,
  fab,
  teamAId,
  teamBId,
  customName,
  bracketId,
  matchId
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const event = useSelector((state) => state.event.event);
  const eventLoading = useSelector((state) => state.event.loading);
  const addGameLoading = useSelector((state) => state.event.addGameLoading);
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const [gameFormat, setGameFormat] = useState(event?.gameFormat);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const errors = methods.formState.errors;

  const eventId = useParams().eventId;

  useEffect(() => {
    if (event === undefined && eventId) {
      dispatch(getEvent(eventId));
    }
  }, [event, eventId, dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createGameName = (data) => {
    let gameFormat = event.gameFormat;
    let gameName = '';

    if (event.venue && data.green !== 'na') {
      gameName = gameName + `${data.green}${data.rink}`;
    }

    if (gameFormat.sections > 0 && section) {
      gameName = gameName + ` - ${section.name}`;
    }

    if (gameFormat.rounds > 0) {
      gameName = gameName + ` - Round ${data.round}`;
    }

    return gameName;
  };

  const onSubmit = async (data) => {
    let gameName = createGameName(data);
    data.name = gameName;

    if (customName) {
      data.name = customName;
    }

    if (event) {
      data.event = event.id;
      data.gameFormat = gameFormat;
      data.gameType = gameFormat.gameType;
      data.venue = event.venue.id;
    }

    if (section) {
      data.section = section.id;
    }

    if (bracketId && matchId) {
      data.bracket = bracketId;
      data.match = matchId;
    }

    dispatch(createGame(data))
      .unwrap()
      .then(() => {
        setOpen(false);
        methods.reset();
      })
      .catch((err) => {
        //TODO Error
        console.log(err);
      });
  };

  const onAdd = async () => {
    methods.handleSubmit(onSubmit)();
  };

  return eventLoading ? (
    <CircularProgress />
  ) : (
    <Fragment>
      {!fab ? (
        <PrimaryButton
          size={'small'}
          variant={'contained'}
          onClick={() => handleClickOpen()}
          onTouchStart={() => handleClickOpen()}>
          Add Game
        </PrimaryButton>
      ) : (
        <Fab color="primary" aria-label="add" onClick={() => handleClickOpen()}>
          <AddIcon />
        </Fab>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'} fullScreen={isMobile}>
        <DialogTitle>Add {customName || 'Game'}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            {/* {event.gameFormat.gameType === GameType.SINGLES ? (
              <EventSinglesVs />
            ) : ( */}
            <EventMultipleVs selectedTeamA={teamAId} selectedTeamB={teamBId} />

            <GreenSelector event={event} />
            {event.gameFormat.rounds > 0 && (
              <Select name="round" control={methods.control} label={'Round'}>
                {(() => {
                  let container = [];
                  for (let i = 0; i < event.gameFormat.rounds; i++) {
                    container.push(
                      <MenuItem key={i} value={i + 1}>
                        Round {i + 1}
                      </MenuItem>
                    );
                  }
                  return container;
                })()}
              </Select>
            )}
            <Checkbox
              name={'live'}
              label="Game is streamed Live."
              control={methods.control}
              error={errors.live}
              value={event?.live || false}
            />
            <br />
            <Checkbox
              name={'featured'}
              label="Game is featured."
              control={methods.control}
              error={errors.featured}
              value={event?.featured || false}
            />

            <Typography variant="h6" my={2}>
              Game Information
            </Typography>
            <Typography>Game Format: {event.gameFormat?.name}</Typography>
            <Typography>
              Game Type: {gameFormatService.getGameTypeString(event.gameFormat?.gameType)}
            </Typography>
          </FormProvider>
          <OverrideGameFormat gameFormat={gameFormat} onChange={(gf) => setGameFormat(gf)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} onTouchStart={handleClose}>
            Cancel
          </Button>
          {addGameLoading ? (
            <CircularProgress />
          ) : (
            <PrimaryButton
              onClick={() => onAdd()}
              onTouchStart={() => onAdd()}
              variant={'contained'}>
              Add
            </PrimaryButton>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AddGameDialog;

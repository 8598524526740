import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import PrimaryButton from '../ui/buttons/primaryButton';
import { updateGame } from '../../features/game/gameSlice';
import Input from '../ui/forms/fields/input';
import GreenSelector from '../ui/clubControls/greenSelector';
import Checkbox from '../ui/forms/fields/checkbox';

const schema = yup.object().shape({
  name: yup.string().required('Please enter game name'),
  rink: yup.number(),
  green: yup.string(),
  live: yup.boolean(),
  featured: yup.boolean()
});

const EditGame = ({ game, open, onClose }) => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.event?.event);
  const eventSections = useSelector((state) => state.event?.event?.sections);

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   setValue,
  //   formState: { errors }
  // } = useForm({
  //   resolver: yupResolver(schema)
  // });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { errors } = methods.formState;

  useEffect(() => {
    if (game.name === undefined || game.name === 'undefined') {
      try {
        const gameSection = eventSections.find((section) => section.id === game.section);
        const sectionGameIndex = gameSection.games.findIndex(
          (sectionGame) =>
            game.teams.teamA.id === sectionGame.teamA && sectionGame.teamB === game.teams.teamB.id
        );

        if (sectionGameIndex > -1) {
          const gameName = gameSection.name + ' Game ' + (sectionGameIndex + 1);
          methods.setValue('name', gameName);
        } else {
          methods.setValue('name', '');
        }
      } catch (error) {
        methods.setValue('name', '');
      }
    } else {
      methods.setValue('name', game.name);
    }

    return () => {
      methods.reset();
    };
  }, [methods.reset, game.name, eventSections, game.section]);

  const saveGame = (data) => {
    dispatch(updateGame({ ...data, id: game.id }));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Game</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Input label="Game Name" {...methods.register('name')} />
          <GreenSelector event={event} game={game} />
          <Checkbox
            name={'live'}
            label="Game is streamed Live."
            control={methods.control}
            error={errors.live}
            value={event?.live || false}
          />
          <br />
          <Checkbox
            name={'featured'}
            label="Game is featured."
            control={methods.control}
            error={errors.featured}
            value={event?.featured || false}
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={() => methods.handleSubmit(saveGame)()}>Save</PrimaryButton>
        <PrimaryButton onClick={() => onClose()}>Cancel</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditGame;

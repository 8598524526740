//Login Page - Using firebase Password/Email Login

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Divider, MenuItem, Paper, Typography } from '@mui/material';
import Input from '../ui/forms/fields/input';
import PrimaryButton from '../ui/buttons/primaryButton';
import { registerUser } from '../../services/auth';
import { useAlert } from '../../contexts/alertContext';
import { useNavigate } from 'react-router-dom';
import AuthTemplate from './authTemplate';
import { getVenues } from '../../features/venue/venueSlice';
import Select from '../ui/forms/fields/select';
import { createUserProfile } from '../../services/user';
import PhoneNumberInput from '../ui/forms/fields/phoneNumberInput';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone is required'),
  password: yup.string().required('Password is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  clubId: yup.string().required('Please select a club')
});

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSigningUp, setIsSigningUp] = React.useState(false);
  const { showAlertSuccess, showAlertError } = useAlert();
  const venues = useSelector((state) => state.venue.venues);
  const loadingVenues = useSelector((state) => state.loadingVenues);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    dispatch(getVenues());
  }, [dispatch]);

  const onSignUp = async (data) => {
    if (!isSigningUp) {
      setIsSigningUp(true);
      try {
        await registerUser(data.email, data.password).then(async (userObject) => {
          await createUserProfile(userObject.user.uid, {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            homeClub: data.clubId,
            role: 'user',
            clubs: []
          });

          showAlertSuccess('Successfully signed up!');
        });
      } catch (err) {
        showAlertError('Please check user information try again.');
      }

      setIsSigningUp(false);
    }
  };

  return (
    <AuthTemplate>
      <Input
        label="First Name"
        variant="outlined"
        {...register('firstName')}
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
      />
      <Input
        label="Last Name"
        variant="outlined"
        {...register('lastName')}
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
      />
      <Input
        label="Email"
        variant="outlined"
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <PhoneNumberInput
        control={control}
        name="phone"
        label="Phone Number"
        error={errors.phone ? true : false}
        helperText={errors.phone ? errors.phone.message : ''}
        defaultValue={'+27'}
      />
      <Input
        label="Password"
        variant="outlined"
        type="password"
        {...register('password')}
        error={!!errors.password}
        helperText={errors.password?.message}
      />

      {loadingVenues ? (
        <Typography>Loading Clubs...</Typography>
      ) : (
        <Select control={control} name="clubId" label="Select a Club">
          {venues.map((venue) => (
            <MenuItem key={venue.id} value={venue.id}>
              {venue.name}
            </MenuItem>
          ))}
        </Select>
      )}

      <Box height={10} />
      <PrimaryButton variant="contained" color="primary" onClick={() => handleSubmit(onSignUp)()}>
        Sign Up
      </PrimaryButton>
      <Divider sx={{ m: 2 }} />
      <PrimaryButton variant="text" color="info" size="small" onClick={() => navigate('/signin')}>
        Already signed up? Sign in here..
      </PrimaryButton>
    </AuthTemplate>
  );
};

export default SignUp;

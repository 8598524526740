import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import bracketService from './bracketService';

export const getBracketById = createAsyncThunk(
  'brackets/getBracketById',
  async (data, thunkAPI) => {
    return await bracketService
      .getById(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getBracketsByEventId = createAsyncThunk(
  'brackets/getBracketsByEventId',
  async (data, thunkAPI) => {
    return await bracketService
      .getByEventId(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const updateBracket = createAsyncThunk('brackets/updateBracket', async (data, thunkAPI) => {
  return await bracketService
    .update(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const generateBracket = createAsyncThunk(
  'brackets/generateBracket',
  async (data, thunkAPI) => {
    return await bracketService
      .generate(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const removeBracket = createAsyncThunk('brackets/removeBracket', async (data, thunkAPI) => {
  return await bracketService
    .remove(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const bracketSlice = createSlice({
  name: 'brackets',
  initialState: {
    bracket: null,
    brackets: []
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBracketById.fulfilled, (state, action) => {
        state.bracket = action.payload;
        state.loading = false;
      })
      .addCase(getBracketById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBracketById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBracketsByEventId.fulfilled, (state, action) => {
        state.brackets = action.payload;
        state.loading = false;
      })
      .addCase(getBracketsByEventId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBracketsByEventId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateBracket.fulfilled, (state, action) => {
        state.bracket = action.payload;
        state.loading = false;
      })
      .addCase(updateBracket.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateBracket.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(generateBracket.fulfilled, (state, action) => {
        state.bracket = action.payload;
        state.generating = false;
      })
      .addCase(generateBracket.rejected, (state, action) => {
        state.generating = false;
      })
      .addCase(generateBracket.pending, (state, action) => {
        state.generating = true;
      })
      .addCase(removeBracket.fulfilled, (state, action) => {
        state.bracket = action.payload;
        state.loading = false;
      })
      .addCase(removeBracket.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(removeBracket.pending, (state, action) => {
        state.loading = true;
      });
  }
});

export const selectBracket = (state) => state.brackets.bracket;

export default bracketSlice.reducer;

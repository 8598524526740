import { Stack, Typography, Badge, Box } from '@mui/material';
import { GameStatus } from '../../../constants/gameConstants';
import ColorDot from '../colorDot';
import ScoreBoxTicker from './scoreBoxTicker';

const TeamEndScoreTicker = ({ team, game }) => {
  const currentSet = game.sets.find((set) => set.id === game.currentSet);

  const getEndScore = (team) => {
    let score = 0;
    if (currentSet) {
      //Calculate all end scores for the team
      for (let i = 0; i < currentSet.scores.length; i++) {
        const end = currentSet.scores[i];
        if (end.teamScores[team]) {
          score += end.teamScores[team];
        }
      }
    }

    return score;
  };

  const teamName = team.players
    .map((player) => {
      if (team.players.length > 2) {
        return player.split(' ')[0].charAt(0) + '. ' + player.split(' ')[1];
      } else {
        return player;
      }
    })
    .join(', ');

  return (
    <Box display="flex" flexDirection={'column'}>
      <ColorDot color={team.color} />
      <Stack px={3} alignItems={'center'} justifyContent={'center'}>
        <Typography variant="caption">{teamName}</Typography>
        {game.status === GameStatus.IN_PROGRESS && currentSet.isDone ? (
          currentSet.winningId ? (
            <ScoreBoxTicker
              teamTotal={game.teamTotals[team.id]}
              score={getEndScore(team.id)}
              emoji={currentSet.winningId === team.id ? '🏆' : '🥈'}
            />
          ) : (
            <ScoreBoxTicker teamTotal={game.teamTotals[team.id]} score={getEndScore(team.id)} />
          )
        ) : null}

        {game.status === GameStatus.COMPLETE && (
          <ScoreBoxTicker
            teamTotal={game.teamTotals[team.id]}
            score={game.teamTotals[team.id]}
            emoji={game.winningTeamId === team.id ? '🏆' : '🥈'}
          />
        )}

        {game.status === GameStatus.PLAYOFF && game.playoff && (
          <ScoreBoxTicker
            teamTotal={game.teamTotals[team.id]}
            score={game.playoff.teamScores[team.id]}
          />
        )}

        {game.status === GameStatus.IN_PROGRESS && !currentSet.isDone && (
          <ScoreBoxTicker teamTotal={game.teamTotals[team.id]} score={getEndScore(team.id)} />
        )}
      </Stack>
    </Box>
  );
};

export default TeamEndScoreTicker;

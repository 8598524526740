import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import teamService from './teamService';
import { setAlertError } from '../alert/alertSlice';

export const removeTeamFromEvent = createAsyncThunk(
  'team/removeTeamFromEvent',
  async (data, thunkAPI) => {
    return await teamService
      .removeTeamFromEvent(data.eventId, data.teamId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err));
        return thunkAPI.rejectWithValue(err);
      });
  }
);

export const teamSlice = createSlice({
  name: 'teams',
  initialState: {
    team: null,
    teams: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeTeamFromEvent.fulfilled, (state, action) => {
        state.removingTeam = false;
        state.teams = action.payload;
      })
      .addCase(removeTeamFromEvent.rejected, (state, action) => {
        state.removingTeam = false;
        state.teams = [];
      })
      .addCase(removeTeamFromEvent.pending, (state, action) => {
        state.removingTeam = true;
        state.teams = [];
      });
  }
});

export default teamSlice.reducer;

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getAuth, EmailAuthProvider } from 'firebase/auth';

let firebaseConfig = {
  apiKey: 'AIzaSyCKuVKvIiaYMes40k0Mk3DIqQObEeUVEDY',
  authDomain: 'bowlscout-staging.firebaseapp.com',
  projectId: 'bowlscout-staging',
  storageBucket: 'bowlscout-staging.firebasestorage.app',
  messagingSenderId: '854545421632',
  appId: '1:854545421632:web:f8ab4a64dc741d2a6916a5'
};

// if (process.env.NODE_ENV === 'production') {
firebaseConfig = {
  apiKey: 'AIzaSyCieaor9yYHSQoVkaMw8xmlxFE5y25Trvo',
  authDomain: 'bowlscout.firebaseapp.com',
  projectId: 'bowlscout',
  storageBucket: 'bowlscout.appspot.com',
  messagingSenderId: '1047908333781',
  appId: '1:1047908333781:web:b5308c6581c6020fc44a5c',
  measurementId: 'G-J8LG2K307M'
};
// }

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const fireStorage = getStorage(app, process.env.REACT_APP_FIRBASE_STOREAGE);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const emailProvider = new EmailAuthProvider();
export const store = firestore;
export const storage = fireStorage;
//firebase login
//firebase init
//firebase deploy

import { auth } from '../config/firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  updatePassword,
  sendEmailVerification,
  signInWithCustomToken,
  signInWithPhoneNumber
} from 'firebase/auth';

export const registerUser = async (email, password) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInUser = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const signInUserWithToken = async (token) => {
  return await signInWithCustomToken(auth, token).catch((error) => {
    console.log(error);
  });
};

export const signInUserWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  return await signInWithPopup(auth, provider);
};

export const signOutUser = async () => {
  return await auth.signOut();
};

//PasswordReset
export const resetUserPassword = async (email) => {
  return await sendPasswordResetEmail(auth, email);
};

//PasswordUpdate
export const updateUserPassword = async (password) => {
  return await updatePassword(auth.currentUser, password);
};

//Send Email Verification
export const sendUserEmailVerification = async () => {
  return await sendEmailVerification(auth.currentUser);
};

export const signInWithPhone = async (phoneNumber, appVerifier) => {
  return await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
};

export const signInCredential = async (credential) => {
  return await signInWithCustomToken(auth, credential);
};

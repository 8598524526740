import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../config/firebase';
import { useAuth } from '../../contexts/authContext';
import GameByStatusCards from '../ui/gameStatusCards/gameByStatusCards';
import { Box, Typography } from '@mui/material';
import BSCircularProgress from '../ui/progress/circularProgress';
import { hasAccess, Roles } from '../../helpers/access';

const CurrentGames = () => {
  const { currentUserProfile } = useAuth();
  const [loading, setLoading] = useState();
  const [games, setGames] = useState([]);
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);

  useEffect(() => {
    setLoading(true);
    setGames([]);
    const collectionRef = collection(store, 'games');
    const q = query(
      collectionRef,
      where('updatedAt', '>=', new Date(Date.now() - 30 * 60000))
      // where('venue', '==', selectedVenue?.id)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const gamesData = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data()
      }));

      setGames(gamesData);
    });

    setLoading(false);

    return () => unsubscribe();
  }, [selectedVenue]);

  return (
    <Box display={'flex'} flexDirection={'row'} p={1} flexWrap={'wrap'} gap={2}>
      {loading ? (
        <BSCircularProgress caption={'Loading Games'} />
      ) : games.length !== 0 ? (
        games.map((game) => (
          <GameByStatusCards
            key={game.id}
            game={game}
            actions={hasAccess(currentUserProfile, Roles.ADMIN)}
          />
        ))
      ) : (
        <Box>
          <Typography variant="caption">No games found</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CurrentGames;

const { default: axiosInstance } = require('../axiosInstance');

const getAllUsers = async () => {
  return axiosInstance.get('/user').then((response) => {
    return response.data;
  });
};

const getUserById = async (userId) => {
  return axiosInstance.get(`/user/${userId}`).then((response) => {
    return response.data;
  });
};

const getUserByInviteToken = async (token) => {
  return axiosInstance.get(`/user/invite/${token}`).then((response) => {
    return response.data;
  });
};

const createUser = async (user) => {
  return axiosInstance.post('/user', user).then((response) => {
    return response.data;
  });
};

const updateUser = async (user) => {
  return axiosInstance.put(`/user/${user.id}`, user).then((response) => {
    return response.data;
  });
};

const deleteUser = async (userId) => {
  return axiosInstance.delete(`/user/${userId}`).then((response) => {
    return response.data;
  });
};

const inviteUser = async (data) => {
  return axiosInstance.post(`/user/invite`, data).then((response) => {
    return response.data;
  });
};

const verifyInviteToken = async (data) => {
  return axiosInstance.put(`/user/invite`, data).then((response) => {
    return response.data;
  });
};

const userService = {
  getAllUsers,
  getUserById,
  getUserByInviteToken,
  createUser,
  updateUser,
  deleteUser,
  inviteUser,
  verifyInviteToken
};

export default userService;

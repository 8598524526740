import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useAuth } from '../contexts/authContext';

const OverlayLayout = (props) => {
  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
  }, []);

  return (
    <Box width={'100%'} sx={{ backgroundColor: 'transparent' }}>
      {props.children}
    </Box>
  );
};

export default OverlayLayout;

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { store } from '../../../config/firebase';
import { GameStatus } from '../../../constants/gameConstants';
import GameByStatusCards from '../../ui/gameStatusCards/gameByStatusCards';
import GameLiveCard from '../../ui/gameStatusCards/gameLiveCard';

// Get event
const LiveScore = ({ match }) => {
  const eventId = useParams().eventId;
  const [game, setGame] = useState({});

  useEffect(() => {
    const gamesRef = collection(store, 'games');
    const q = query(
      gamesRef,
      where('event', '==', eventId.toString()),
      where('live', '==', true),
      where('status', 'in', [GameStatus.IN_PROGRESS, GameStatus.PLAYOFF])
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      console.log(
        'Query Snapshot:',
        querySnapshot.docs.map((doc) => doc.data())
      );
      if (!querySnapshot.empty) {
        const firstGame = {
          id: querySnapshot.docs[0].id,
          ...querySnapshot.docs[0].data()
        };
        setGame(firstGame);
      } else {
        console.log('No games found');
        // If no games match the query, reset the state
        setGame(null);
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [eventId]);

  return <Box m={2}>{game && <GameLiveCard game={game} />}</Box>;
};

export default LiveScore;

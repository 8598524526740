import { Box, Stack } from '@mui/material';
import PrimaryButton from '../buttons/primaryButton';
import ScoreDisplay from '../playerDisplay/scoreDisplay';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext';

const GameCompleteCard = ({ event, game, actions }) => {
  const navigate = useNavigate();
  const sectionId = useParams().sectionId;

  const { currentUser } = useAuth();

  return (
    <Stack justifyContent={'space-between'} height="100%">
      <ScoreDisplay game={game} />

      {/* {(actions || currentUser.uid === game.scorer) && ( */}
      <Box display={'flex'} flexDirection={'row'} alignSelf={'center'} justifySelf={'flex-end'}>
        {currentUser ? (
          <PrimaryButton
            color="info"
            variant="contained"
            onClick={() => navigate(`/game/scoring/${game.id}`)}>
            View
          </PrimaryButton>
        ) : (
          <PrimaryButton
            color="info"
            variant="contained"
            onClick={() => navigate(`/embed/games/${sectionId}/${game.id}`)}>
            View Score Card
          </PrimaryButton>
          ///embed/games/:sectionId/:gameId
        )}
      </Box>
      {/* )} */}
    </Stack>
  );
};

export default GameCompleteCard;

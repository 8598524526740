import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../../config/firebase';
import GameByStatusCards from '../../ui/gameStatusCards/gameByStatusCards';
import { Box, Input, Stack, Typography } from '@mui/material';
import BSCircularProgress from '../../ui/progress/circularProgress';

const EmbeddedActiveGames = ({ event }) => {
  const [loading, setLoading] = useState();
  const [games, setGames] = useState([]);
  const [filterInput, setFilterInput] = useState('');
  const [filteredGames, setFilteredGames] = useState([]);

  useEffect(() => {
    setLoading(true);
    setGames([]);
    const collectionRef = collection(store, 'games');
    const q = query(
      collectionRef,
      where('updatedAt', '>=', new Date(Date.now() - 30 * 60000)),
      where('event', '==', event)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const gamesData = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data()
      }));

      console.log('gamesData', gamesData);

      setGames(gamesData);
      setFilteredGames(gamesData);
    });

    setLoading(false);

    return () => unsubscribe();
  }, [event]);

  useEffect(() => {
    if (filterInput === '') setFilteredGames(games);
    setFilteredGames(
      games.filter((game) => {
        const teamA = game.teams.teamA.players.join(' ').toLowerCase();
        const teamB = game.teams.teamB.players.join(' ').toLowerCase();
        return (
          teamA.includes(filterInput.toLowerCase()) || teamB.includes(filterInput.toLowerCase())
        );
      })
    );
  }, [filterInput]);

  //   const teamName = team.players
  //     .map((player) => {
  //       if (team.players.length > 2) {
  //         return player.split(' ')[0].charAt(0) + '. ' + player.split(' ')[1];
  //       } else {
  //         return player;
  //       }
  //     })
  //     .join(', ');

  return (
    <Stack gap={2}>
      <Input
        name="filter"
        label="Filter"
        placeholder="Filter by Surname"
        sx={{ textAlignLast: 'center', textAlign: 'center' }}
        value={filterInput}
        onChange={(e) => setFilterInput(e.target.value)}
      />

      <Box display={'flex'} flexDirection={'row'} p={1} flexWrap={'wrap'} gap={2}>
        {loading ? (
          <BSCircularProgress caption={'Loading Games'} />
        ) : filteredGames.length !== 0 ? (
          filteredGames.map((game) => (
            <GameByStatusCards key={game.id} game={game} actions={false} showSectionWinners />
          ))
        ) : (
          <Box>
            <Typography variant="caption">
              There are currently no active games being scored. (In the last 30 min)
            </Typography>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default EmbeddedActiveGames;

import PropTypes from 'prop-types';
import { Box, Paper, Stack, Typography } from '@mui/material';
import ScoreDisplay from '../playerDisplay/scoreDisplay';
import PoweredBy from '../poweredBy';
import GameSectionWins from '../gameSectionWins';

const GameLiveCard = ({ game }) => {
  if (!game) {
    return null; // Render nothing if no game is provided
  }

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: 350,
        py: 2,
        borderRadius: 5,
        position: 'relative'
      }}
      elevation={3}>
      <Stack width={'100%'} alignItems={'center'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'} m={0}>
          <Typography>{game.name ? game.name : ''}</Typography>
        </Box>

        <Typography variant={'caption'} p={0} m={0}>
          Current End: {game.currentEnd}
        </Typography>

        {game.sets && (
          <Stack>
            <ScoreDisplay game={game} />
            <Box height={'20px'}>
              <GameSectionWins game={game} />
            </Box>
          </Stack>
        )}

        <Box height={'20px'}>
          <PoweredBy width={'100px'} />
        </Box>
      </Stack>
    </Paper>
  );
};

// Add PropTypes for validation
GameLiveCard.propTypes = {
  game: PropTypes.shape({
    name: PropTypes.string // Game name (optional)
  })
};

export default GameLiveCard;

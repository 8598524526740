import { Box, Button } from '@mui/material';
import { PhoneAuthCredential, PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { signInCredential, signInUserWithToken, signInWithPhone } from '../../../services/auth';
import AuthTemplate from '../authTemplate';
import { auth } from '../../../config/firebase';
import Form from '../../ui/forms/index';
import PhoneNumberInput from '../../ui/forms/fields/phoneNumberInput';
import Input from '../../ui/forms/fields/input';
import BSCircularProgress from '../../ui/progress/circularProgress';
import { useAuth } from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
  phone: yup.string().required('Please enter a phone number.')
});

const PhoneAuth = () => {
  const [verification, setVerification] = useState(null);
  const [otp, setOtp] = useState(null);
  const [recaptcha, setRecaptcha] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { currentUser, currentUserProfile } = useAuth();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (!recaptcha) {
      const newRecaptcha = new RecaptchaVerifier(auth, 'otp-button', {
        size: 'invisible',
        callback: (response) => {}
      });

      setRecaptcha(newRecaptcha);
    }
    return reset();
  }, [reset, recaptcha]);

  const onSubmit = async (data) => {
    setLoading(true);
    const confirmationResult = await signInWithPhone(data.phone, recaptcha).catch((error) => {
      console.error(error);
      setLoading(false);
    });

    setVerification(confirmationResult);
    setLoading(false);
  };

  const onContinue = async () => {
    if (!verification) {
      console.error('Verification object is missing');
      return;
    }

    try {
      await verification.confirm(otp);
      console.log('Current User:', auth.currentUser);

      if (currentUserProfile) {
        navigate('/create-profile');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <>
      <AuthTemplate>
        <Form>
          {verification ? (
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
              <Input
                name={'OTP'}
                label={'OTP'}
                control={control}
                error={errors.OTP ? true : false}
                helperText={errors.OTP ? errors.OTP.message : ''}
                onChange={(e) => setOtp(e.target.value)}
              />
              <Button
                id={'continue-button-button'}
                variant="contained"
                color="success"
                onClick={onContinue}>
                Continue
              </Button>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
              <PhoneNumberInput
                control={control}
                name="phone"
                label="Phone Number"
                error={errors.phone ? true : false}
                helperText={errors.phone ? errors.phone.message : ''}
                defaultValue={'+27'}
              />

              {loading && <BSCircularProgress />}
              <Button
                id={'otp-button'}
                variant="contained"
                color="success"
                onClick={handleSubmit(onSubmit)}
                disabled={loading}>
                Send OTP
              </Button>
            </Box>
          )}
        </Form>
      </AuthTemplate>
    </>
  );
};

export default PhoneAuth;

import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Paper, Typography } from '@mui/material';
import { getAllUsers } from '../../features/user/userSlice';
import BSCircularProgress from '../ui/progress/circularProgress';

import { DataGrid } from '@mui/x-data-grid';
import InviteDialog from './inviteDialog';

const paginationModel = { page: 0, pageSize: 5 };
const columns = [
  {
    field: 'fullName',
    headerName: 'Fullname',
    width: 150,
    valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200
    //sortable: false
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 150
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: (params) => (
      <Fragment>
        <Button color="info">Edit</Button>
        <Button color="error">Delete</Button>
      </Fragment>
    )
  }
];

const Users = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loading);
  const users = useSelector((state) => state.user.users);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <Box>
      <Typography variant="h4">Users</Typography>
      <InviteDialog />
      {loading ? (
        <BSCircularProgress caption={'Loading Users...'} />
      ) : (
        <Paper sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={10}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10]}
            sx={{ border: 0 }}
          />
        </Paper>
      )}
    </Box>
  );
};

export default Users;

import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { store } from '../../config/firebase';
import { useAuth } from '../../contexts/authContext';
import GameByStatusCards from '../ui/gameStatusCards/gameByStatusCards';
import { Box } from '@mui/material';
import BSCircularProgress from '../ui/progress/circularProgress';
import { hasAccess, Roles } from '../../helpers/access';

const UserScoring = () => {
  const { currentUser, currentUserProfile } = useAuth();
  const [loading, setLoading] = useState();
  const [games, setGames] = useState([]);

  useEffect(() => {
    setLoading(true);
    const collectionRef = collection(store, 'games');
    const q = query(collectionRef, where('scorer.id', '==', currentUser.uid));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const gamesData = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data()
      }));

      setGames(gamesData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [currentUser]);

  return (
    <Box display={'flex'} flexDirection={'row'} p={1} flexWrap={'wrap'} gap={2}>
      {loading ? (
        <BSCircularProgress caption={'Loading Games'} />
      ) : (
        games.map((game) => (
          <GameByStatusCards
            key={game.id}
            game={game}
            actions={hasAccess(currentUserProfile, Roles.ADMIN)}
          />
        ))
      )}
    </Box>
  );
};

export default UserScoring;

import { Close } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react';

const ViewScorecardPhoto = ({ game }) => {
  const [open, setOpen] = useState(false);
  // const [photoUrl, setPhotoUrl] = useState('');

  // useEffect(() => {
  //   console.log(game.scorecardPhoto);
  //   if (game.scorecardPhoto instanceof Blob) {
  //     const objectUrl = URL.createObjectURL(game.scorecardPhoto);
  //     setPhotoUrl(objectUrl);

  //     return () => {
  //       URL.revokeObjectURL(objectUrl); // Clean up the object URL
  //     };
  //   } else if (typeof game.scorecardPhoto === 'string') {
  //     setPhotoUrl(game.scorecardPhoto);
  //   } else {
  //     setPhotoUrl('');
  //   }
  // }, [game.scorecardPhoto]);

  return (
    <Fragment>
      <Button size="small" color="info" onClick={() => setOpen(true)}>
        View Scorecard
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <AppBar color="info" sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close">
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Scorecard for {game.name}
            </Typography>
            <Button autoFocus color="inherit" onClick={() => setOpen(false)}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            {!game.scorecardPhoto ? (
              <Typography>No Scorecard</Typography>
            ) : (
              <img src={game.scorecardPhoto} alt="Scorecard" width="100%" />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ViewScorecardPhoto;

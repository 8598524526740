import { Button, useTheme } from '@mui/material';

const YellowButton = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Button sx={{ bgcolor: 'yellow.main', color: theme.palette.background.paper }} {...props}>
      {children}
    </Button>
  );
};

export default YellowButton;

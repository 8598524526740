import { Box, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { store } from '../../../config/firebase';
import GameLiveCard from '../../ui/gameStatusCards/gameLiveCard';
import Ticker from '../../ui/ticker';
import ScoreDisplayTicker from '../../ui/ticker/scoreDisplayTicker';
import { GameStatus } from '../../../constants/gameConstants';

// Create scroller for active games
const LiveTicker = () => {
  const eventId = useParams().eventId;
  const [loading, setLoading] = useState();
  const [games, setGames] = useState([]);

  useEffect(() => {
    setLoading(true);
    setGames([]);
    const collectionRef = collection(store, 'games');
    const q = query(
      collectionRef,
      where('updatedAt', '>=', new Date(Date.now() - 30 * 60000)),
      where('event', '==', eventId)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const gamesData = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data()
      }));

      setGames(gamesData);
    });

    setLoading(false);

    return () => unsubscribe();
  }, [eventId]);

  return (
    <Box m={2}>
      <Ticker>
        {games.map((game, index) => (
          <Paper
            key={index}
            sx={{
              width: '100%',
              px: 2,
              py: 1,
              borderRadius: 5,
              position: 'relative',
              border: 3,
              borderColor: game.status === GameStatus.PLAYOFF ? 'warning.main' : 'info.main'
            }}
            elevation={3}>
            <ScoreDisplayTicker key={index} game={game} />
          </Paper>
        ))}
      </Ticker>
    </Box>
  );
};

export default LiveTicker;

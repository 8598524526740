import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  getSvgIconUtilityClass,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import Form from '../ui/forms/index';
import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import YellowButton from '../ui/buttons/yellowButton';
import { set } from 'mongoose';
import { generateBracket } from '../../features/bracket/bracketSlice';
import { useParams } from 'react-router-dom';

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
  useSections: yup.boolean().default(true)
});

// Generate tournament bracket from sections.
const GenerateBracket = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const generating = useSelector((state) => state.bracket.generating);
  const event = useSelector((state) => state.event.event);
  const eventId = useParams().eventId;
  const sections = event.sections;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema)
  });

  const watchRoundType = watch('type');

  useEffect(() => {
    if (watchRoundType) {
      //Check if the sections matches the type of rounds. If not, show an error message. 16 sections for Last16, 8 sections for Last8, 4 sections for Last4
      checkSections(watchRoundType);
    }
  }, [watchRoundType]);

  const checkSections = (watchRoundType) => {
    if (watchRoundType === 'Last16' && sections.length !== 16) {
      setError('type', { message: 'Invalid number of sections for Last 16' });
      return;
    } else if (watchRoundType === 'Last8' && sections.length !== 8) {
      setError('type', { message: 'Invalid number of sections for Last 8' });
      return;
    } else if (watchRoundType === 'Last4' && sections.length !== 4) {
      setError('type', { message: 'Invalid number of sections for Last 4' });
      return;
    }

    //Remove error message if the sections matches the type of rounds
    clearErrors('type');
  };

  const getVS = (section) => {
    if (section) {
      const vsSection = sections.find((s) => s.id === section.winnerPlaysSection);
      return vsSection ? vsSection.name : '';
    }
  };

  const onSubmit = (data) => {
    data.eventId = eventId;
    dispatch(generateBracket(data))
      .unwrap()
      .then((result) => {
        onSuccess();
      });
  };

  return (
    <Box>
      <Form>
        <Input
          name="name"
          label="Bracket Name"
          error={errors?.name}
          helperText={errors?.name?.message}
          {...register('name')}
        />
        <Select
          name="type"
          label="Bracket Type"
          control={control}
          defaultValue="Last16"
          error={errors?.type}
          helperText={errors?.type?.message}>
          <MenuItem value="Last16">Last 16</MenuItem>
          <MenuItem value="Last8">Last 8</MenuItem>
          <MenuItem value="Last4">Last 4</MenuItem>
        </Select>
        <Box m={2} />
        <Typography variant="h6">Initial Setup</Typography>
        {/* Table Sections, VS Section */}
        <Table size="small" sx={{ my: 2 }}>
          <TableBody>
            {sections.map((section) => (
              <TableRow key={section.id}>
                <TableCell>{section.name}</TableCell>
                <TableCell>VS</TableCell>
                <TableCell>{getVS(section)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {generating ? (
          <CircularProgress color="yellow.main" />
        ) : (
          <YellowButton onClick={handleSubmit(onSubmit)}>Generate Bracket</YellowButton>
        )}
      </Form>
    </Box>
  );
};

export default GenerateBracket;

import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getEvent } from '../../features/event/eventSlice';
import {
  getEventScorecard,
  getEventSectionsLeaderboard
} from '../../features/leaderboard/leaderboardSlice';
import BSCircularProgress from '../ui/progress/circularProgress';
import SectionScorecard from '../ui/scoreboard/sectionScorecard.jsx';
import SectionTotalsCard from '../ui/scoreboard/sectionTotalsCard.jsx';
import TotalsCard from '../ui/scoreboard/totalsCard.jsx';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const ScoreCards = ({ event, section }) => {
  const dispatch = useDispatch();
  const loadingScorecard = useSelector((state) => state.leaderboard.loadingScorecard);
  const scorecard = useSelector((state) => state.leaderboard.scorecard);
  //   const sectionsLeaderboard = useSelector((state) => state.leaderboard.sectionLeaderboard);
  const [selectedTab, setSelectedTab] = React.useState(0);

  //Get rounds form the gameFormat
  const eventRounds = event.gameFormat.rounds;

  useEffect(() => {
    dispatch(getEventScorecard(event.id));
  }, [dispatch, event.id]);

  // const getTeamsByRounds = (section) => {
  //   const rounds = [];
  //   for (let i = 0; i < eventRounds; i++) {
  //     const teams = section.teams.filter((t) => t.round === i + 1);

  //     if (teams.length > 0) {
  //       rounds.push(teams);
  //     }
  //   }

  //   return rounds;
  // };

  return loadingScorecard ? (
    <BSCircularProgress caption={'Loading...'} size={30} />
  ) : (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)}>
        <Tab label="Scoreboard" value={0} />
        {/* <Tab label="Detailed Section Scoreboard" value={1} />
        <Tab label="Leaderboard" value={2} /> */}
      </Tabs>

      <CustomTabPanel value={selectedTab} index={0}>
        <Box
          sx={{ width: '100%' }}
          display={'flex'}
          flexDirection={'row'}
          gap={3}
          justifyContent={'center'}
          flexWrap={'wrap'}>
          {scorecard && scorecard.map((teamScores) => <TotalsCard teamScores={teamScores.teams} />)}
        </Box>
      </CustomTabPanel>
      {/* <CustomTabPanel value={selectedTab} index={1}>
        {loadingScorecard.map((section) => (
          <Box key={section.id} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h5" my={2}>
              {section.sectionName}
            </Typography>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              gap={1}
              flexWrap={'wrap'}>
              {getTeamsByRounds(section).map((round, index) => (
                <SectionScorecard teams={round} round={index + 1} key={index} />
              ))}
            </Box>
          </Box>
        ))}
      </CustomTabPanel> */}
      {/* <CustomTabPanel value={selectedTab} index={2}>
        Leaderboard
      </CustomTabPanel> */}
    </Box>
  );
};

const Scorecard = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const event = useSelector((state) => state.event.event);
  const loadingEvent = useSelector((state) => state.event.loading);

  useEffect(() => {
    dispatch(getEvent(eventId));
    // dispatch(getEventSectionsLeaderboard({ eventId }));
  }, [dispatch, eventId]);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h4">Scoreboard</Typography>

      {loadingEvent ? (
        <BSCircularProgress caption={'Loading Event'} size={30} />
      ) : (
        <ScoreCards event={event} />
      )}
    </Paper>
  );
};

export default Scorecard;

//Login Page - Using firebase Password/Email Login

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Divider, MenuItem, Paper, Typography } from '@mui/material';
import Input from '../ui/forms/fields/input';
import PrimaryButton from '../ui/buttons/primaryButton';
import { registerUser, signInUserWithToken } from '../../services/auth';
import { useAlert } from '../../contexts/alertContext';
import { useNavigate, useParams } from 'react-router-dom';
import AuthTemplate from './authTemplate';
import { getVenues } from '../../features/venue/venueSlice';
import Select from '../ui/forms/fields/select';
import { createUserProfile } from '../../services/user';
import {
  getUserById,
  getUserByInviteToken,
  verifyInviteToken
} from '../../features/user/userSlice';
import PhoneNumberInput from '../ui/forms/fields/phoneNumberInput';

import { isValidPhoneNumber } from 'react-phone-number-input';
import { signInWithCustomToken } from 'firebase/auth';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone is required'),
  password: yup.string().required('Password is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required')
});

const Invite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSigningUp, setIsSigningUp] = React.useState(false);
  const { showAlertSuccess, showAlertError } = useAlert();
  const venues = useSelector((state) => state.venue.venues);
  const loadingVenues = useSelector((state) => state.loadingVenues);

  const loadingUser = useSelector((state) => state.user.loading);
  const userDetails = useSelector((state) => state.user.invitedUser);
  const userInviteToken = useParams().inviteToken;

  const redirectTo = useParams().redirectTo;

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    dispatch(getVenues());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserByInviteToken(userInviteToken));
  }, [dispatch, userInviteToken]);

  useEffect(() => {
    if (userDetails) {
      setValue('email', userDetails.email);
      setValue('phone', userDetails.phone);
      setValue('firstName', userDetails.firstName);
      setValue('lastName', userDetails.lastName);
    }
  }, [userDetails, setValue]);

  const onSignUp = async (data) => {
    if (!isSigningUp) {
      setIsSigningUp(true);
      try {
        data.token = userInviteToken;
        dispatch(verifyInviteToken(data))
          .unwrap()
          .then((response) => {
            signInUserWithToken(response.customAuthToken).then(() => {
              navigate(redirectTo);
              showAlertSuccess('Successfully signed in!');
            });
          });
      } catch (err) {
        showAlertError('Please check user information try again.');
      }

      setIsSigningUp(false);
    }
  };

  return (
    <AuthTemplate>
      <Input
        label="First Name"
        variant="outlined"
        {...register('firstName')}
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
      />
      <Input
        label="Last Name"
        variant="outlined"
        {...register('lastName')}
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
      />
      <Input
        label="Email"
        variant="outlined"
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <PhoneNumberInput
        control={control}
        name="phone"
        label="Phone"
        error={errors.phone}
        helperText={errors.phone?.message}
      />

      <Input
        label="Password"
        variant="outlined"
        type="password"
        {...register('password')}
        error={!!errors.password}
        helperText={errors.password?.message}
      />

      {loadingVenues ? (
        <Typography>Loading Clubs...</Typography>
      ) : (
        <Select control={control} name="clubId" label="Select a Club">
          {venues.map((venue) => (
            <MenuItem key={venue.id} value={venue.id}>
              {venue.name}
            </MenuItem>
          ))}
        </Select>
      )}

      <Box height={10} />
      <PrimaryButton variant="contained" color="primary" onClick={() => handleSubmit(onSignUp)()}>
        Continue
      </PrimaryButton>
    </AuthTemplate>
  );
};

export default Invite;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  useTheme
} from '@mui/material';

// import { useWindowSize } from '@uidotdev/usehooks';
import AddIcon from '@mui/icons-material/Add';

import { getBracketsByEventId, removeBracket } from '../../features/bracket/bracketSlice';

import GenerateBracket from './generateBracket';
import YellowButton from '../ui/buttons/yellowButton';

import { Roles, hasAccess } from '../../helpers/access';
import { useAuth } from '../../contexts/authContext';
import SingleBracket from '../ui/bracket';

const Bracket = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const { currentUserProfile } = useAuth();

  const brackets = useSelector((state) => state.bracket.brackets);
  const loading = useSelector((state) => state.bracket.loading);

  const [openNewBracket, setOpenNewBracket] = useState(false);
  const [selectedBracket, setSelectedBracket] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    dispatch(getBracketsByEventId(eventId));
  }, [eventId, dispatch]);

  useEffect(() => {
    if (brackets.length > 0) {
      setSelectedBracket(brackets[0]);
    }
  }, [brackets]);

  const generateNewBracket = () => {
    setOpenNewBracket(true);
  };

  const closeNewBracket = () => {
    setOpenNewBracket(false);
  };

  // const handleOpenDelete = (bracket) => {
  //   setSelectedBracket(bracket);
  //   setOpenDelete(true);
  // };

  // const handleDeleteClose = () => {
  //   setOpenDelete(false);
  //   setSelectedBracket(null);
  // };

  // const handleDelete = () => {
  //   dispatch(removeBracket(selectedBracket.id))
  //     .unwrap()
  //     .then(() => {
  //       setOpenDelete(false);
  //       setSelectedBracket(null);
  //     });
  // };

  return loading ? (
    <CircularProgress color="yellow" />
  ) : (
    <Box>
      {hasAccess(currentUserProfile, Roles.ADMIN) && brackets.length === 0 && (
        <YellowButton onClick={generateNewBracket} startIcon={<AddIcon />}>
          Generate Bracket
        </YellowButton>
      )}

      {selectedBracket && <SingleBracket bracket={selectedBracket} />}

      <Dialog open={openNewBracket} onClose={closeNewBracket} fullWidth maxWidth="sm">
        <DialogTitle>Generate New Bracket</DialogTitle>
        <DialogContent>
          <GenerateBracket onSuccess={closeNewBracket} />
        </DialogContent>
      </Dialog>

      {/* <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{`Delete Bracket: ${selectedBracket?.name}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to delete this bracket. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  );
};

export default Bracket;

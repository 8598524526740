import axios from 'axios';
import Cookies from 'js-cookie';
import { auth } from '../config/firebase';

const axiosInstance = axios.create({
  baseURL: '/api/',
  headers: { 'Content-Type': 'application/json' }
});

axiosInstance.interceptors.request.use(async (request) => {
  if (auth.currentUser) {
    request.headers.Authorization = await auth.currentUser
      .getIdToken()
      .then((idToken) => {
        return idToken;
      })
      .catch(() => {
        return '';
      });
  }

  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove('auth');
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

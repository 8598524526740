import { Box } from '@mui/material';

const ColorDot = ({ color, noOffset }) => {
  return (
    <Box
      sx={{
        backgroundColor: `${color}.main`,
        width: 10,
        height: 10,
        borderRadius: '100%'
      }}
      alignSelf={noOffset ? 'center' : 'flex-end'}></Box>
  );
};

export default ColorDot;

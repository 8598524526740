import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';

const StyledSectionTotalTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
    borderBottom: 'none'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    borderBottom: 'none'
  }
}));

const SectionTotalsCard = ({ section }) => {
  const event = useSelector((state) => state.event.event);

  if (section.sectionTotals && section.sectionTotals.teams.length !== 0) {
    let teamSetTotals = section.sectionTotals.teams;
    const mutableTeamSetTotals = Array.from(teamSetTotals);

    // Sorting by:
    // 1. Total Points
    // 2. Total Sets Won
    // 3. Total Net Points

    mutableTeamSetTotals.sort((a, b) => {
      // if (a.teamId === section.sectionWinningTeam) return -1;
      // if (b.teamId === section.sectionWinningTeam) return 1;

      if (a.totalPoints === b.totalPoints) {
        if (a.setsWon === b.setsWon) {
          return b.totalNet - a.totalNet;
        }

        return b.setsWon - a.setsWon;
      }

      return b.totalPoints - a.totalPoints;
    });

    teamSetTotals = mutableTeamSetTotals;

    return (
      <TableContainer
        key={section.id}
        component={Paper}
        sx={{
          borderRadius: 3,
          maxWidth: 450,
          border: '1px solid',
          borderColor: 'blue.main'
        }}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <StyledSectionTotalTableCell padding="normal">
                <Typography variant="body1" fontWeight={'bold'}>
                  {section.sectionName}
                </Typography>
              </StyledSectionTotalTableCell>
              <StyledSectionTotalTableCell padding="normal" align={'center'}>
                <Tooltip title="Total Games Played">P</Tooltip>
              </StyledSectionTotalTableCell>
              <StyledSectionTotalTableCell padding="normal" align={'center'}>
                <Tooltip title="Total Games Won">W</Tooltip>
              </StyledSectionTotalTableCell>
              <StyledSectionTotalTableCell padding="normal" align={'center'}>
                <Tooltip title="Total Games Lost">L</Tooltip>
              </StyledSectionTotalTableCell>
              <StyledSectionTotalTableCell padding="normal" align={'center'}>
                <Tooltip title="Total Points">
                  <Typography variant="p" fontWeight={'bold'} color="white">
                    Pts
                  </Typography>
                </Tooltip>
              </StyledSectionTotalTableCell>
              {event.gameFormat.sets > 1 && (
                <StyledSectionTotalTableCell padding="normal" align={'center'}>
                  <Tooltip title="Total Sets Won">SW</Tooltip>
                </StyledSectionTotalTableCell>
              )}

              <StyledSectionTotalTableCell padding="normal" align={'center'}>
                <Tooltip title="Total Net Points">Net</Tooltip>
              </StyledSectionTotalTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamSetTotals.map((team) => (
              <TableRow key={team.teamId}>
                <StyledSectionTotalTableCell size="small" padding="normal">
                  {team.teamName}
                </StyledSectionTotalTableCell>
                <StyledSectionTotalTableCell size="small" align={'center'}>
                  {team.totalGames}
                </StyledSectionTotalTableCell>
                <StyledSectionTotalTableCell size="small" align={'center'}>
                  {team.totalGamesWon}
                </StyledSectionTotalTableCell>
                <StyledSectionTotalTableCell size="small" align={'center'}>
                  {team.totalGamesLost}
                </StyledSectionTotalTableCell>
                <StyledSectionTotalTableCell
                  size="small"
                  align={'center'}
                  sx={{ backgroundColor: 'blue.main' }}>
                  <Typography variant="p" fontWeight={'bold'} color="white">
                    {team.totalPoints}
                  </Typography>
                </StyledSectionTotalTableCell>
                {event.gameFormat.sets > 1 && (
                  <StyledSectionTotalTableCell size="small" align={'center'}>
                    {team.setsWon}
                  </StyledSectionTotalTableCell>
                )}

                <StyledSectionTotalTableCell size="small" align={'center'}>
                  {team.totalNet}
                </StyledSectionTotalTableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow key={'footer'} sx={{ backgroundColor: 'info.main' }} padding="none">
              <TableCell colSpan={'100%'} padding={'none'} align={'center'} sx={{ p: 1 }}>
                {section.sectionWinningTeam ? (
                  <Typography variant="caption" color={'white'} fontWeight={'bold'}>
                    Section won by {section.sectionWinningTeam.teamName}.{' '}
                    {section.winnerPlaysSection !== '' &&
                      'Plays ' + section.winnerPlaysSection + ' winner.'}
                  </Typography>
                ) : section.winnerPlaysSection !== '' ? (
                  <Typography variant="caption" color={'white'} fontWeight={'bold'}>
                    Section winner plays {section.winnerPlaysSection} winner.
                  </Typography>
                ) : (
                  <Typography variant="caption" color={'white'} fontWeight={'bold'}>
                    Section In Progress
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <TableContainer
        key={section.id}
        component={Paper}
        sx={{
          borderRadius: 3,
          maxWidth: 400,
          border: '1px solid',
          borderColor: 'blue.main'
        }}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <StyledSectionTotalTableCell padding="normal">
                <Typography variant="body1" fontWeight={'bold'}>
                  {section.sectionName}
                </Typography>
              </StyledSectionTotalTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledSectionTotalTableCell size="small" padding="normal">
                No data available yet, check back again later.
              </StyledSectionTotalTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default SectionTotalsCard;

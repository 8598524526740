import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getEvent } from '../../features/event/eventSlice';
import { getEventSectionsLeaderboard } from '../../features/leaderboard/leaderboardSlice';
import BSCircularProgress from '../ui/progress/circularProgress';
import SectionScorecard from '../ui/scoreboard/sectionScorecard.jsx';
import SectionTotalsCard from '../ui/scoreboard/sectionTotalsCard.jsx';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const SectionScoreboard = ({ event, section }) => {
  const dispatch = useDispatch();
  const loadingLeaderboard = useSelector((state) => state.leaderboard.loading);
  const sectionsLeaderboard = useSelector((state) => state.leaderboard.sectionLeaderboard);
  // const leaderboard = useSelector((state) => state.leaderboard.leaderboard);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [sortedSections, setSortedSections] = React.useState([]);

  //Get rounds form the gameFormat
  const eventRounds = event.gameFormat.rounds;

  useEffect(() => {
    if (event) {
      dispatch(getEventSectionsLeaderboard({ eventId: event.id }));
    }
  }, [dispatch, event.id]);

  useEffect(() => {
    if (sectionsLeaderboard) {
      setSortedSections(sectionsLeaderboard);
    }
    console.log(sectionsLeaderboard);
  }, [sectionsLeaderboard]);

  const getTeamsByRounds = (section) => {
    const rounds = [];
    for (let i = 0; i < eventRounds; i++) {
      const teams = section.teams.filter((t) => t.round === i + 1);

      if (teams.length > 0) {
        rounds.push({ teams, round: i + 1 });
      }
    }

    return rounds;
  };

  return loadingLeaderboard ? (
    <BSCircularProgress caption={'Loading...'} size={30} />
  ) : (
    <Box>
      {sortedSections && (
        <Box sx={{ width: '100%' }}>
          <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)}>
            <Tab label="Log" value={0} />
            <Tab label="Detailed Scorecard" value={1} />
            {/* <Tab label="Leaderboard" value={2} /> */}
          </Tabs>

          <CustomTabPanel value={selectedTab} index={0}>
            <Box
              sx={{ width: '100%' }}
              display={'flex'}
              flexDirection={'row'}
              gap={3}
              justifyContent={'center'}
              flexWrap={'wrap'}
              py={2}>
              {sortedSections.map((section, index) => (
                <Fragment key={index}>
                  <SectionTotalsCard section={section} />
                </Fragment>
              ))}
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={selectedTab} index={1}>
            {sortedSections.map((section) => (
              <Box key={section.id} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h5" my={2}>
                  {section.sectionName}
                </Typography>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  gap={1}
                  flexWrap={'wrap'}>
                  {getTeamsByRounds(section).map((tr, index) => (
                    <Fragment key={index}>
                      <SectionScorecard teams={tr.teams} round={tr.round} />
                    </Fragment>
                  ))}
                </Box>
              </Box>
            ))}
          </CustomTabPanel>
          {/* <CustomTabPanel value={selectedTab} index={2}>
        Leaderboard
      </CustomTabPanel> */}
        </Box>
      )}
    </Box>
  );
};

const Scoreboard = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const event = useSelector((state) => state.event.event);
  const loadingEvent = useSelector((state) => state.event.loading);

  useEffect(() => {
    dispatch(getEvent(eventId));
    dispatch(getEventSectionsLeaderboard({ eventId }));
  }, [dispatch, eventId]);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h4" textAlign={'center'}>
        Scores
      </Typography>

      {loadingEvent ? (
        <BSCircularProgress caption={'Loading Event'} size={30} />
      ) : (
        <SectionScoreboard event={event} />
      )}
    </Paper>
  );
};

export default Scoreboard;

import { Stack, Typography, Box } from '@mui/material';
import ScoreBox from './scoreBoxTicker';
import ColorDot from '../colorDot';

const TeamFinalScoreTicker = ({ team, game }) => {
  const teamName = team.players
    .map((player) => {
      if (team.players.length > 2) {
        return player.split(' ')[0].charAt(0) + '. ' + player.split(' ')[1];
      } else {
        return player;
      }
    })
    .join(', ');

  return (
    <Box display="flex" flexDirection={'column'}>
      <ColorDot color={team.color} />
      <Stack px={3} alignItems={'center'} justifyContent={'center'}>
        <Typography variant="caption">{teamName}</Typography>
        {game.teamTotals && (
          <Box display="flex" justifyContent={'center'} alignItems={'center'}>
            <Typography variant="h4" role="img" aria-label="Trophy">
              {game.winningTeamId === team.id ? '🏆' : game.winningTeamId === null ? '🏅' : '🥈'}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default TeamFinalScoreTicker;

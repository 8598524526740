import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from '../main.routes';
import { AuthProvider } from '../contexts/authContext';
import { AlertProvider } from '../contexts/alertContext';
import { createBowlscoutTheme } from '../resources/theme';

import ColorModeProvider, { useColorMode } from '../hooks/colorModeProvider';
import StagingAlert from './ui/stagingAlert';
import TitleProvider from '../hooks/titleProvider';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { useServiceWorker } from '../useServiceWorker';
import { Alert, Button, Snackbar } from '@mui/material';
import { clearAlert } from '../features/alert/alertSlice';

function MyApp() {
  const { mode } = useColorMode();
  const dispatch = useDispatch();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [showNewVersionPopup, setShowNewVersionPopup] = useState(false);
  const { message, type, open } = useSelector((state) => state.alert);

  const theme = useMemo(
    () =>
      createBowlscoutTheme({
        mode
      }),
    [mode]
  );

  useEffect(() => {
    if (showReload && waitingWorker) {
      setShowNewVersionPopup(true);
    } else setShowNewVersionPopup(false);
  }, [waitingWorker, showReload, reloadPage]);

  const handleClose = (event, reason) => {
    dispatch(clearAlert());
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={type} variant="filled">
          {message !== undefined && message.length > 0
            ? message
            : type === 'error'
              ? 'An unknown error accrued please report this to the administrator.'
              : ''}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={showNewVersionPopup}
        title="New Update">
        <Alert
          action={
            <Button variant="contained" color={'dark'} onClick={() => reloadPage()}>
              Update
            </Button>
          }
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          A new version is available.
        </Alert>
      </Snackbar>
      <AlertProvider>
        <StagingAlert isStaging={process.env.NODE_ENV !== 'production'} />
        <TitleProvider>
          <AuthProvider>
            <BrowserRouter>
              <MainRoutes />
            </BrowserRouter>
          </AuthProvider>
        </TitleProvider>
      </AlertProvider>
    </ThemeProvider>
  );
}

function ToggleColorMode() {
  return (
    <ColorModeProvider>
      <MyApp />
    </ColorModeProvider>
  );
}

export default ToggleColorMode;

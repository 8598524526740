import { Box, Typography } from '@mui/material';
import TeamEndScore from '../playerDisplay/teamEndScore';
import { GameStatus } from '../../../constants/gameConstants';
import TeamFinalScore from '../playerDisplay/teamFinalScore';
import TeamFinalScoreTicker from './teamFinalScoreTicker';
import TeamEndScoreTicker from './teamEndScoreTicker';

const ScoreDisplayTicker = ({ game }) => {
  const currentSet = game.sets.find((set) => set.id === game.currentSet);
  const getElementByGameStatus = (team) => {
    switch (game.status) {
      case GameStatus.COMPLETE:
        return <TeamFinalScoreTicker team={team} game={game} />;
      case GameStatus.IN_PROGRESS:
        return currentSet && currentSet.isDone && currentSet.winningId ? (
          <TeamFinalScoreTicker team={team} game={game} />
        ) : (
          <TeamEndScoreTicker team={team} game={game} />
        );
      case GameStatus.PLAYOFF:
        return <TeamEndScoreTicker team={team} game={game} />;
      default:
        return <TeamEndScoreTicker team={team} game={game} />;
    }
  };

  return game && game.teams ? (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={'space-evenly'}
      alignItems={'center'}
      gap={1}>
      {getElementByGameStatus(game.teams.teamA)}
      <Typography>VS</Typography>
      {getElementByGameStatus(game.teams.teamB)}
    </Box>
  ) : null;
};

export default ScoreDisplayTicker;

import { Box, Stack, Typography } from '@mui/material';
import TeamEndScore from './teamEndScore';
import TeamFinalScore from './teamFinalScore';
import GameControl from '../gameControls/gameControl';
import ScoreControl from '../gameControls/scoreControl';
import PlayoffScoreControl from '../gameControls/playoffScoreControl';
import { GameStatus } from '../../../constants/gameConstants';
import GameControlSecondary from '../gameControls/gameControlSecondary';
import { useAuth } from '../../../contexts/authContext';

const ScoringDisplay = ({ game }) => {
  const { authenticated } = useAuth();

  return game && game.teams ? (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={'space-evenly'}
        alignItems={'center'}
        gap={1}>
        {game.status === GameStatus.COMPLETE ? (
          <TeamFinalScore team={game.teams.teamA} game={game} />
        ) : (
          <TeamEndScore team={game.teams.teamA} game={game} />
        )}
        <GameControl game={game} />
        {game.status === GameStatus.COMPLETE ? (
          <TeamFinalScore team={game.teams.teamB} game={game} />
        ) : (
          <TeamEndScore team={game.teams.teamB} game={game} />
        )}
      </Box>

      {authenticated ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={'space-evenly'}
          alignItems={'center'}
          gap={1}>
          {game.status === GameStatus.PLAYOFF && game.playoff ? (
            <PlayoffScoreControl game={game} team={game.teams.teamA} />
          ) : (
            <ScoreControl team={game.teams.teamA} game={game} />
          )}

          {game.status === GameStatus.PLAYOFF && game.playoff ? (
            <PlayoffScoreControl game={game} team={game.teams.teamB} />
          ) : (
            <ScoreControl team={game.teams.teamB} game={game} />
          )}
        </Box>
      ) : null}

      {authenticated ? <GameControlSecondary game={game} /> : null}
    </>
  ) : null;
};

export default ScoringDisplay;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userService from './userService';
import { setAlertError, setAlertSuccess, setAlertInfo, setAlertWarning } from '../alert/alertSlice';

export const getAllUsers = createAsyncThunk('user/getAllUsers', async (data, thunkAPI) => {
  return await userService
    .getAllUsers()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err));
      return thunkAPI.rejectWithValue(err);
    });
});

export const getUserById = createAsyncThunk('user/getUserById', async (userId, thunkAPI) => {
  return await userService
    .getUserById(userId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err));
      return thunkAPI.rejectWithValue(err);
    });
});

export const getUserByInviteToken = createAsyncThunk(
  'user/getUserByInviteToken',
  async (token, thunkAPI) => {
    return await userService
      .getUserByInviteToken(token)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err));
        return thunkAPI.rejectWithValue(err);
      });
  }
);

export const createUser = createAsyncThunk('user/createUser', async (user, thunkAPI) => {
  return await userService
    .createUser(user)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('User created'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err));
      return thunkAPI.rejectWithValue(err);
    });
});

export const updateUser = createAsyncThunk('user/updateUser', async (user, thunkAPI) => {
  return await userService
    .updateUser(user)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('User updated'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err));
      return thunkAPI.rejectWithValue(err);
    });
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (userId, thunkAPI) => {
  return await userService
    .deleteUser(userId)
    .then((response) => {
      thunkAPI.dispatch(setAlertInfo('User deleted'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err));
      return thunkAPI.rejectWithValue(err);
    });
});

export const inviteUser = createAsyncThunk('user/inviteUser', async (data, thunkAPI) => {
  return await userService
    .inviteUser(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertInfo('User invite sent.'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err));
      return thunkAPI.rejectWithValue(err);
    });
});

export const verifyInviteToken = createAsyncThunk(
  'user/verifyInviteToken',
  async (data, thunkAPI) => {
    return await userService
      .verifyInviteToken(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err));
        return thunkAPI.rejectWithValue(err);
      });
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    users: [],
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.users = [];
        state.loading = false;
      })
      .addCase(getAllUsers.pending, (state, action) => {
        state.users = [];
        state.loading = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(getUserById.pending, (state, action) => {
        state.user = null;
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(createUser.pending, (state, action) => {
        state.user = null;
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.user = null;
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.user = null;
        state.loading = true;
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(inviteUser.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(inviteUser.pending, (state, action) => {
        state.user = null;
        state.loading = true;
      })
      .addCase(verifyInviteToken.fulfilled, (state, action) => {
        state.invitedUser = action.payload;
        state.loading = false;
      })
      .addCase(verifyInviteToken.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(verifyInviteToken.pending, (state, action) => {
        state.user = null;
        state.loading = true;
      })
      .addCase(getUserByInviteToken.fulfilled, (state, action) => {
        state.invitedUser = action.payload;
        state.loading = false;
      })
      .addCase(getUserByInviteToken.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(getUserByInviteToken.pending, (state, action) => {
        state.user = null;
        state.loading = true;
      });
  }
});

export default userSlice.reducer;

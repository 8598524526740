import React from 'react';
import { Outlet } from 'react-router-dom';
import OverlayLayout from './overlayLayout';

const OverlayRoute = (props) => {
  return (
    <OverlayLayout>
      <Outlet />
    </OverlayLayout>
  );
};

export default OverlayRoute;

import axiosInstance from '../axiosInstance';

const getById = async (id) => {
  try {
    const response = await axiosInstance.get(`/bracket/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getByEventId = async (eventId) => {
  try {
    const response = await axiosInstance.get(`/bracket/event/${eventId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const update = async (id, data) => {
  try {
    await axiosInstance.put(`/bracket/${id}`, data);
    return true;
  } catch (error) {
    console.error(error);
  }
};

const generate = async (data) => {
  try {
    const response = await axiosInstance.post(`/bracket/generate/${data.eventId}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const remove = async (id) => {
  try {
    await axiosInstance.delete(`/bracket/${id}`);
    return true;
  } catch (error) {
    console.error(error);
  }
};

const bracketService = {
  getById,
  getByEventId,
  update,
  generate,
  remove
};

export default bracketService;

const GameStatus = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  COMPLETE: 3,
  PLAYOFF: 4
};

const GameType = {
  SINGLES: 2,
  PAIRS: 1,
  TRIPS: 3,
  FOURS: 4
};

const getGameType = (gameType) => {
  switch (gameType) {
    case GameType.SINGLES:
      return 'Singles';
    case GameType.PAIRS:
      return 'Pairs';
    case GameType.TRIPS:
      return 'Trips';
    case GameType.FOURS:
      return 'Fours';
    default:
      return 'Unknown';
  }
};

export { GameStatus, GameType, getGameType };

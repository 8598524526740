//Add Edit event schema with yup and reacthook form

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PrimaryButton from '../ui/buttons/primaryButton';
import eventService from '../../features/event/eventService';

import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import { finaliseSection } from '../../features/event/eventSlice';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  rounds: yup.number().default(1).required('Rounds is required.'),
  order: yup.number().required('Order is required.'),
  winnerPlaysSection: yup.string()
});

const AddEditSectionDialog = ({ open, onClose, event, section }) => {
  const dispatch = useDispatch();

  const finalising = useSelector((state) => state.event.finalisingSection);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: section
  });

  const [saving, setSaving] = useState(false);
  const [openFinalseSectionConfirm, setOpenFinaliseSectionConfirm] = useState(false);
  const [canFinalise, setCanFinalise] = useState(false);
  const sections = event.sections;

  const games = useSelector((state) => state.game.games);

  useEffect(() => {
    if (section) {
      setValue('name', section.name);
      setValue('rounds', section.rounds);
      setValue('order', section.order);
      setValue('winnerPlaysSection', section.winnerPlaysSection ? section.winnerPlaysSection : '');
    }
  }, [section, setValue]);

  useEffect(() => {
    if (!games || !section) return;

    const gamesInSection = games.filter((g) => g.section === section.id);
    const allGamesCompleted = gamesInSection.every((g) => g.winningTeamId);
    setCanFinalise(allGamesCompleted && !section.finalised);
  }, [games, section, event]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onSubmit = async (data) => {
    setSaving(true);
    if (event && section) {
      await eventService.updateSection(event.id, { ...data, id: section.id });
    } else if (event) {
      await eventService.addSection(event.id, data);
    }

    setSaving(false);
    onClose();
  };

  const handleAddEdit = () => {
    handleSubmit(onSubmit)();
  };

  const handleAutoGenerateName = () => {
    const numSections = event.sections?.length ?? 0;
    const name = `Section ${numSections + 1}`;
    setValue('name', name, { shouldValidate: true, shouldDirty: true });
  };

  const openFinaliseSection = () => {
    setOpenFinaliseSectionConfirm(true);
  };

  const closeFinaliseSection = () => {
    setOpenFinaliseSectionConfirm(false);
  };

  const handleFinaliseSection = () => {
    // Finalise the section
    dispatch(finaliseSection({ eventId: event.id, sectionId: section.id }));
    closeFinaliseSection();
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h4" component="h1">
            {section ? 'Edit Section' : 'New Section'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box display="flex" flexDirection={'row'} alignItems="center">
              <Input
                label="Name"
                {...register('name')}
                error={errors.name}
                disabled={saving}
                focused={true}
              />

              {!section && (
                <PrimaryButton
                  variant="text"
                  color="secondary"
                  sx={{ ml: 1, width: '250px', height: '40px' }}
                  onClick={() => handleAutoGenerateName()}>
                  Auto Generate
                </PrimaryButton>
              )}
            </Box>

            {!section && (
              <Input
                label="Rounds"
                {...register('rounds')}
                error={errors.rounds}
                disabled={saving}
                defaultValue={event.gameFormat.rounds}
                type={event.gameFormat.rounds > 0 ? 'number' : 'hidden'}
              />
            )}

            {sections && sections.length > 0 && (
              <Select name="winnerPlaysSection" label="Winner Plays Section" control={control}>
                <MenuItem value={''}>None</MenuItem>
                {sections.map((section) => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            <Input
              label="Order"
              type="number"
              error={errors.order}
              placeholder={'Order of Sections'}
              {...register('order')}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {saving ? (
            <CircularProgress />
          ) : (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              width={'100%'}>
              {canFinalise && !section?.winningTeam && (
                <>
                  <Button
                    variant={'contained'}
                    color="success"
                    onClick={openFinaliseSection}
                    disabled={finalising}>
                    Finalise Section
                  </Button>
                  {finalising && <CircularProgress color="success" />}
                </>
              )}

              {section?.finalised && (
                <Typography variant="caption" color="success">
                  Section was won by {section.winner.teamName}
                </Typography>
              )}

              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'flex-end'}
                width={!canFinalise ? '100%' : 'auto'}>
                <PrimaryButton variant={'outlined'} color={'error'} onClick={onClose}>
                  Cancel
                </PrimaryButton>
                <PrimaryButton variant={'contained'} onClick={() => handleAddEdit()}>
                  {section ? 'Update' : 'Add'}
                </PrimaryButton>
              </Box>
            </Box>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openFinalseSectionConfirm} onClose={closeFinaliseSection}>
        <DialogTitle id="alert-dialog-title">Finalise Section</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to finalise this section? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeFinaliseSection}>Cancel</Button>
          <Button variant="contained" color="success" onClick={handleFinaliseSection}>
            Finalise
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AddEditSectionDialog;

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from '../ui/forms/index';
import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { inviteUser } from '../../features/user/userSlice';
import { getVenues } from '../../features/venue/venueSlice';

const userSchema = yup.object().shape({
  email: yup.string().email(),
  phone: yup.string().required(),
  role: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  homeClub: yup.string()
});

const AddEditUserForm = ({ onSuccess, onCancel }) => {
  const dispatch = useDispatch();
  const clubs = useSelector((state) => state.venue.venues);
  const selectedClub = useSelector((state) => state.venue.selectedVenue);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(userSchema)
  });

  useEffect(() => {
    return reset();
  }, [reset]);

  const onSubmit = (data) => {
    console.log(data);
    dispatch(inviteUser(data));
    // onSuccess();
  };

  return (
    <Form>
      <Box display="flex" flexDirection={'column'} my={1}>
        <Typography color="warning" mb={2}>
          You are going to add a user to <b>{selectedClub.name}</b>.
        </Typography>
      </Box>
      <Input
        name="email"
        label="Email"
        error={errors.email}
        helperText={errors.email?.message}
        {...register('email')}
      />
      <Input
        name="phone"
        label="Phone"
        error={errors.phone}
        helperText={errors.phone?.message}
        placeholder={'0123456789'}
        {...register('phone')}
      />
      <Input
        name="firstName"
        label="First Name"
        error={errors.firstName}
        helperText={errors.firstName?.message}
        {...register('firstName')}
      />
      <Input
        name="lastName"
        label="Last Name"
        error={errors.lastName}
        helperText={errors.lastName?.message}
        {...register('lastName')}
      />

      <Select
        name="homeClub"
        label="Home Club"
        control={control}
        defaultValue={selectedClub && selectedClub.id}
        helperText={errors.homeClub?.message}>
        {clubs &&
          clubs.map((club) => (
            <MenuItem value={club.id} key={club.id}>
              {club.name}
            </MenuItem>
          ))}
      </Select>

      <Box
        display="flex"
        flexDirection={'column'}
        my={2}
        p={2}
        border={'1px solid'}
        borderColor={'warning'}
        borderRadius={5}>
        <Typography variant="h6">Selecting a role:</Typography>
        <Typography color="warning" mb={2}>
          You are going to add a user to <b>{selectedClub.name}</b>. If this is not the club you
          want to add the user too, please cancel and switch to the correct club.
        </Typography>
        <Select
          name="role"
          label="Role"
          control={control}
          defaultValue="user"
          helperText={errors.role?.message}>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="scorer">Scorer</MenuItem>
          <MenuItem value="user">User</MenuItem>
        </Select>
      </Box>

      <Button type="submit" onClick={handleSubmit(onSubmit)}>
        Submit
      </Button>
      <Button onClick={onCancel}> Cancel </Button>
    </Form>
  );
};

export default AddEditUserForm;

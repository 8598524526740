import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../buttons/primaryButton';
import { useEffect, useState } from 'react';
import ScoreDisplay from '../playerDisplay/scoreDisplay';
import { useNavigate, useParams } from 'react-router-dom';
import InputScoreDialog from '../../score/inputScoreDialog';

import { useAuth } from '../../../contexts/authContext';
import { Roles, hasAccess } from '../../../helpers/access';

import { getEvent } from '../../../features/event/eventSlice';
import GameSectionWins from '../gameSectionWins';

const GameInProgressCard = ({ event, game, actions, showSectionWinners }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scoreWayOpen, setScoreWayOpen] = useState(false);
  const { currentUser } = useAuth();
  const sectionId = useParams().sectionId;

  const selectedEvent = useSelector((state) => state.event.event);

  useEffect(() => {
    if (!selectedEvent && game) {
      dispatch(getEvent(game.event));
    }
  }, [selectedEvent, game, dispatch]);

  const { authenticated, currentUserProfile } = useAuth();

  const gameScorer = game.scorer?.id || game.scorer;

  const handleCloseScoreWay = () => {
    setScoreWayOpen(false);
  };

  return (
    <>
      <Stack justifyContent={'space-between'} height="100%">
        <ScoreDisplay game={game} />

        <Box display={'flex'} flexDirection={'row'} alignSelf={'center'} justifySelf={'flex-end'}>
          {hasAccess(currentUserProfile, Roles.SCORER) ||
          (currentUser && currentUser.uid === gameScorer) ? (
            <PrimaryButton
              color="success"
              variant="contained"
              onClick={() => setScoreWayOpen(true)}>
              Score Game
            </PrimaryButton>
          ) : (
            <PrimaryButton
              color="info"
              variant="contained"
              onClick={() => navigate(`/embed/games/${sectionId}/${game.id}`)}>
              View Score Card
            </PrimaryButton>
          )}
        </Box>
        {showSectionWinners && <GameSectionWins game={game} />}
      </Stack>

      <Dialog open={scoreWayOpen} onClose={handleCloseScoreWay} fullWidth maxWidth="sm">
        <DialogTitle>Score Game</DialogTitle>
        <DialogContent>
          <DialogContentText> How would you like to score the game.</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button onClick={handleCloseScoreWay} color="primary">
            Cancel
          </Button>

          {hasAccess(currentUserProfile, Roles.SCORER) ||
          (currentUser && currentUser.uid === gameScorer) ? (
            <>
              {!selectedEvent?.liveScoreOnly && (
                <InputScoreDialog game={game} onOpen={() => setScoreWayOpen(false)} />
              )}

              <Button
                variant="contained"
                onClick={() => navigate(`/game/scoring/${game.id}`)}
                color="primary">
                Score Live
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GameInProgressCard;

import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { GameStatus } from '../../../constants/gameConstants';
import UndoButton from './undoButton';
import { saveScorecardPhoto, startNewSet, startPlayoff } from '../../../features/game/gameSlice';
import CameraCapture from '../cameraCapture';
import ViewScorecardPhoto from './viewScorecardPhoto';
import { hasAccess, Roles } from '../../../helpers/access';

import { useAuth } from '../../../contexts/authContext';

const GameControl = ({ game }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const hasSets = game.gameFormat.sets > 1 && game.status !== GameStatus.PLAYOFF;
  const savingPhoto = useSelector((state) => state.game.savingScorecardPhoto);

  const { currentUserProfile, authenticated } = useAuth();

  const savePhoto = (image) => {
    dispatch(saveScorecardPhoto({ gameId: game.id, image }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Create a canvas to resize the image
          const canvas = document.createElement('canvas');
          const maxWidth = 1920; // Set the maximum width
          const maxHeight = 1080; // Set the maximum height

          let width = img.width;
          let height = img.height;

          // Maintain aspect ratio while resizing
          if (width > height) {
            if (width > maxWidth) {
              height = (height * maxWidth) / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = (width * maxHeight) / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the resized image to a Base64 string
          const resizedImage = canvas.toDataURL('image/jpeg', 0.8); // 0.8 = 80% quality
          savePhoto(resizedImage);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Stack alignItems={'center'}>
      {game.status === GameStatus.IN_PROGRESS && hasSets && (
        <>
          <Typography>{`Set`}</Typography>
          <Typography>{`${game.sets.length} / ${game.gameFormat.sets}`}</Typography>
        </>
      )}

      {game.status === GameStatus.PLAYOFF && game.playoff && (
        <Typography>{`Playoff: ${game.playoff.ends.length} / ${game.gameFormat.playoffMaxPoints}`}</Typography>
      )}

      {game.status === GameStatus.IN_PROGRESS && (
        <>
          <Typography>{`End`}</Typography>
          <Typography>{`${game.currentEnd} / ${game.gameFormat.ends === 0 ? '♾️' : game.gameFormat.ends}`}</Typography>
        </>
      )}

      <UndoButton game={game} />

      {game.status === GameStatus.COMPLETE ? (
        savingPhoto ? (
          <CircularProgress color="info" />
        ) : (
          <>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              capture="environment"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />

            {authenticated && hasAccess(currentUserProfile, Roles.SCORER) && (
              <Button variant="contained" color="info" onClick={handleButtonClick}>
                {game.scorecardPhoto ? 'Retake Photo' : 'Upload Picture'}
              </Button>
            )}

            {game.scorecardPhoto && <ViewScorecardPhoto game={game} />}
          </>
        )
      ) : null}
    </Stack>
  );
};

export default GameControl;

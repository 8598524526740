// React
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setEvent, setLoadingEvent } from '../../features/event/eventSlice';

// Store
import { doc, onSnapshot } from 'firebase/firestore';
import { store } from '../../config/firebase';

// UI
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddEditSectionDialog from './addEditSectionDialog';
import PrimaryButton from '../ui/buttons/primaryButton';
import EventSectionCard from '../ui/cards/eventSectionCard';
import EventPlayerSetupDialog from './eventPlayerSetupDialog';
import { getGameByEventId } from '../../features/game/gameSlice';
import GameByStatusCards from '../ui/gameStatusCards/gameByStatusCards';
import AddGameDialog from '../games/addGameDialog';
import { useTitle } from '../../hooks/titleProvider';

import AddIcon from '@mui/icons-material/Add';
import PlayerIcon from '@mui/icons-material/Person';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import BlockIcon from '@mui/icons-material/Block';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import { useAuth } from '../../contexts/authContext';
import { Roles, hasAccess } from '../../helpers/access';

const Event = () => {
  let { eventId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const { authenticated, currentUserProfile } = useAuth();

  const dispatch = useDispatch();
  const event = useSelector((state) => state.event.event);
  const games = useSelector((state) => state.game.games);
  const loading = useSelector((state) => state.event.loading);

  const [open, setOpen] = useState(false);
  const [openAddEditSection, setOpenAddEditSection] = useState(false);
  const [openPlayerAdd, setOpenPlayerAdd] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  const [rounds, setRounds] = useState([]);
  const [sections, setSections] = useState([]);
  const [filterRounds, setFilterRound] = useState('');
  const [filterSection, setFilterSection] = useState('');
  const [filteredGames, setFilteredGames] = useState(games);
  const [filterGreens, setFilterGreen] = useState('');

  const { setTitle } = useTitle();

  //const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(setLoadingEvent(true));
    const docRef = doc(store, 'events', eventId); // replace eventId with your actual event ID

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const eventData = { id: docSnap.id, ...docSnap.data() };
        dispatch(setEvent(eventData));

        if (eventData.hasSections && !eventData.sections) {
          setOpenAddEditSection(true);
        }

        if (eventData.hasSections && eventData.sections) {
          const sortedSections = [...eventData.sections].sort((a, b) =>
            a.order > b.order ? 1 : -1
          );
          setSections(sortedSections);
        }

        setTitle(eventData.name);

        dispatch(getGameByEventId(eventData.id));
      } else {
        console.log('No such document!');
      }

      dispatch(setLoadingEvent(false));
    });

    return () => unsubscribe();
  }, [eventId, dispatch]);

  useEffect(() => {
    if (filteredGames.length === 0) {
      setFilteredGames(games);
    }
  }, [games]);

  useEffect(() => {
    let _fgames = games;

    if (filterSection !== '') {
      _fgames = _fgames.filter((g) => g.section === filterSection);
    }

    if (filterRounds !== '') {
      _fgames = _fgames.filter((g) => g.round === filterRounds);
    }

    if (filterGreens !== '') {
      _fgames = _fgames.filter((g) => g.green === filterGreens);
    }

    setFilteredGames(_fgames);
  }, [filterSection, filterRounds, filterGreens, games]);

  const handleOpenAddGame = (section) => {
    setSelectedSection(section);
    setOpen(true);
  };

  const handlePlayerAddClose = () => {
    setOpenPlayerAdd(false);
  };

  const handleSectionAddEditClose = () => {
    setOpenAddEditSection(false);
    setSelectedSection(null);
  };

  const handleSectionAddEditOpen = (section) => {
    setOpenAddEditSection(true);
    if (section) {
      setSelectedSection(section);
    }
  };

  const handleViewSection = (section) => {
    navigate('/events/' + eventId + '/section/' + section.id);
  };

  const clearFilters = () => {
    setFilterRound('');
    setFilterSection('');
    setFilterGreen('');
    setFilteredGames(games);
  };

  return !event && loading ? (
    <Box
      display="flex"
      flexDirection={'column'}
      height={'100vh'}
      justifyContent="center"
      alignItems="center">
      <CircularProgress size={'60'} color={'primary'} />
    </Box>
  ) : (
    event && (
      <>
        <Stack>
          <Box display="flex" gap={1}>
            {sections ? (
              <Fab
                size="small"
                color="secondary"
                aria-label="add"
                variant="extended"
                onClick={() => navigate(`/events/${eventId}/scoreboard`)}
                sx={{ fontSize: '0.7rem' }}>
                <TrophyIcon />
                Scoreboard
              </Fab>
            ) : (
              <Fab
                size="small"
                color="blue"
                aria-label="add"
                variant="extended"
                onClick={() => navigate(`/events/${eventId}/log`)}
                sx={{ fontSize: '0.7rem' }}>
                <TrophyIcon />
                Log
              </Fab>
            )}

            {hasAccess(currentUserProfile, Roles.ADMIN) && <EventPlayerSetupDialog event={event} />}

            <Fab
              size="small"
              color="yellow.main"
              sx={{ bgcolor: 'yellow.main', fontSize: '0.7rem', lineHeight: '1' }}
              variant="extended"
              onClick={() => navigate(`/events/${eventId}/bracket`)}>
              <DoubleArrowIcon />
              Open Bracket
            </Fab>
          </Box>
          <Box m={1} />
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {event && event.hasSections && (
              <Box>
                <Typography mb={2}>Sections</Typography>
                <Box display="flex" flexWrap={'wrap'}>
                  {sections?.map((section, index) => (
                    <EventSectionCard
                      key={index}
                      section={section}
                      event={event}
                      onAddGame={() => handleOpenAddGame(section)}
                      onViewSection={() => handleViewSection(section)}
                      onUpdateSection={() => handleSectionAddEditOpen(section)}
                    />
                  ))}
                </Box>
              </Box>
            )}

            <Box my={2}>
              <Box display={'flex'} alignItems={'center'} gap={2} flexWrap={'wrap'}>
                <Typography>All Games</Typography>
                <FormControl sx={{ width: 200, my: 2 }}>
                  <InputLabel id={'filterSectionsLabel'}>Filter Sections</InputLabel>
                  <Select
                    labelId="filterSectionsLabel"
                    value={filterSection}
                    onChange={(event) => setFilterSection(event.target.value)}>
                    <MenuItem value={''}>None</MenuItem>
                    {sections &&
                      sections.map((sec) => (
                        <MenuItem key={sec.id} value={sec.id}>
                          {sec.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: 200, my: 2 }}>
                  <InputLabel id={'filterRoundsLabel'}>Filter Rounds</InputLabel>
                  <Select
                    labelId="filterRoundsLabel"
                    value={filterRounds}
                    onChange={(event) => setFilterRound(event.target.value)}>
                    <MenuItem value={''}>None</MenuItem>
                    {(() => {
                      let container = [];
                      for (let i = 0; i < event.gameFormat.rounds; i++) {
                        container.push(
                          <MenuItem key={i} value={i + 1}>
                            Round {i + 1}
                          </MenuItem>
                        );
                      }
                      return container;
                    })()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: 200, my: 2 }}>
                  <InputLabel id={'filterGreenLabel'}>Filter Green</InputLabel>
                  <Select
                    labelId={'filterGreenLabel'}
                    value={filterGreens}
                    onChange={(event) => setFilterGreen(event.target.value)}>
                    <MenuItem value={''}>None</MenuItem>
                    {event.venue.greens?.map((green, index) => (
                      <MenuItem key={green.name} value={green.name}>
                        {green.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button size="small" variant="text" color="primary" onClick={clearFilters}>
                  Clear
                </Button>
              </Box>

              <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={2}>
                {filteredGames && filteredGames.length > 0 ? (
                  filteredGames.map((game, index) => <GameByStatusCards game={game} key={index} />)
                ) : (
                  <Typography>No Games</Typography>
                )}
              </Box>
            </Box>
          </Box>

          <AddEditSectionDialog
            open={openAddEditSection}
            onClose={() => handleSectionAddEditClose()}
            event={event}
            section={selectedSection}
          />
        </Stack>
        <Box sx={{ position: 'relative' }}>
          <Box display="flex" gap={1} sx={{ position: 'fixed', bottom: 64, right: 16 }}>
            {hasAccess(currentUserProfile, Roles.ADMIN) && (
              <>
                {event.hasSections ? (
                  <Fab color="info" aria-label="add" onClick={() => handleSectionAddEditOpen()}>
                    <AddIcon />
                  </Fab>
                ) : (
                  <AddGameDialog event={event} fab />
                )}
              </>
            )}
          </Box>
        </Box>
      </>
    )
  );
};

export default Event;
